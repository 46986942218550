/*
 * @Descripttion: 
 * @Date: 2021-04-22 14:08:42
 * @LastEditTime: 2021-04-29 20:08:34
 */

export default {
  /**
   * @description 金钱转换，分转元
   */
  moneyStr(str) {
    if (!str) return 0.00;
    return parseFloat(str / 100).toFixed(2);
  },
  bytesToSize(bytes) {
    console.log(bytes);
    if (bytes == 0) return '0B';
    let k = 1024;
    let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    let num = bytes / Math.pow(k, i);
    return num.toPrecision(3) + sizes[i];
  }
};