var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "layout"
  }, [!_vm.isMobile() ? _c("businessHeader") : _vm._e(), _c("div", {
    staticClass: "layout-main position-re"
  }, [_c("router-view", {
    attrs: {
      isMobile: _vm.isMobile()
    }
  }), !_vm.isMobile() ? _c("sidebar-btn", {
    ref: "sidebar",
    attrs: {
      bottom: "calc(3% + 94px)",
      name: "客服中心",
      icon: _vm.iconName
    },
    on: {
      handleClick: _vm.contactService,
      handleHover: _vm.handleHover,
      handleOut: _vm.handleOut
    }
  }) : _vm._e()], 1), _vm.$route.name != "ShopDetails" ? _c("businessFooter") : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };