import "core-js/modules/es.array.push.js";
import homeApi from '../../api/home/index';
export default {
  name: 'Collect',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      shopList: [],
      page: 1
    };
  },
  mounted() {
    this.getShopList();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 用户收藏店铺
    async getShopList() {
      const option = {
        page: 1,
        page_size: 15
      };
      try {
        const data = await homeApi.favourite(option);
        this.shopList = this.shopList.concat(data.shops);
      } catch (error) {}
    },
    // 触底加载更多
    handleScroll(e) {
      // 获取滚动的距离
      let scrollTop = e.target.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      let clientHeight = e.target.clientHeight;
      // 获取屏幕(浏览器)高度
      let scrollHeight = e.target.scrollHeight;
      // 如果滚动到接近底部，自动加载下一页
      if (this.page < this.pageTotal && scrollTop + clientHeight - scrollHeight >= 0) {
        // 事件处理
        this.page++;
        this.getShopList();
      }
    },
    handleCard(id) {
      this.$router.push({
        path: "/waimai/shopDetails",
        query: {
          shopId: id
        }
      });
    }
  }
};