var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-details-wrap"
  }, [_c("div", {
    staticClass: "title-wrap bg_c_white flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "title color_75"
  }, [_c("i", {
    staticClass: "el-icon-back cursor",
    on: {
      click: function ($event) {
        return _vm.$emit("back");
      }
    }
  }), _vm._v(" 订单详情 ")]), _c("div", {
    staticClass: "flex flex-align-center"
  }, _vm._l(_vm.detailsInfo.operations, function (btnItem, i) {
    return _c("div", {
      key: i,
      staticClass: "btn-sty margin-l-10",
      style: "color:" + btnItem.txcolor + ";background:" + btnItem.bgcolor,
      on: {
        click: function ($event) {
          return _vm.hanleBtn(btnItem.op_id);
        }
      }
    }, [_vm._v(_vm._s(btnItem.name))]);
  }), 0)]), _c("div", {
    staticClass: "info-box bg_c_white"
  }, [_c("div", {
    staticClass: "info-order"
  }, [_c("div", {
    staticClass: "info-order-top"
  }, [_c("div", {
    staticClass: "flex flex-align-center"
  }, [_c("div", {
    staticClass: "color_main font-s-18"
  }, [_vm._v(_vm._s(_vm.detailsInfo.status_title))]), _vm.detailsInfo.order_status == "0" ? _c("div", {
    staticClass: "color_75 font-s-12 padding-l-15"
  }, [_vm._v("逾期未支付订单将自动取消")]) : _vm._e()]), _c("div", {
    staticClass: "flex flex-align-center flex-between color_75 font-s-12 padding-t-5"
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("div", [_vm._v(_vm._s(_vm.detailsInfo.expect_shipping_time))]), _c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v(_vm._s(_vm.detailsInfo.pay_name))]), _vm.detailsInfo.pay_name ? _c("div", {
    staticClass: "line"
  }) : _vm._e(), _c("div", [_vm._v(_vm._s(_vm.detailsInfo.delivery_name))]), _vm.detailsInfo.delivery_name ? _c("div", {
    staticClass: "line"
  }) : _vm._e(), _c("div", [_vm._v("订单号：" + _vm._s(_vm.detailsInfo.order_no))]), _vm.detailsInfo.package_code ? _c("div", {
    staticClass: "line"
  }) : _vm._e(), _vm.detailsInfo.package_code ? _c("div", [_vm._v("取餐码：" + _vm._s(_vm.detailsInfo.package_code))]) : _vm._e()]), _c("div", [_c("span", [_vm._v("支付金额：")]), _c("span", {
    staticClass: "color_main price"
  }, [_vm._v(_vm._s(_vm.detailsInfo.total_money))])])])]), _c("div", {
    staticClass: "info-people"
  }, [_c("div", [_vm._v(_vm._s(_vm.detailsInfo.receiver_address))]), _c("div", {
    staticClass: "flex flex-align-center color_75 padding-t-8"
  }, [_c("span", [_vm._v(_vm._s(_vm.detailsInfo.receiver_name))]), _c("span", {
    staticClass: "padding-l-10"
  }, [_vm._v(_vm._s(_vm.detailsInfo.receiver_mobile))])]), _c("div", {
    staticClass: "padding-t-8"
  }, [_c("span", [_vm._v("骑手：" + _vm._s(_vm.detailsInfo.rider_name ? _vm.detailsInfo.rider_name : "暂无"))]), _c("span", {
    staticClass: "padding-l-15"
  }, [_vm._v("联系方式：" + _vm._s(_vm.detailsInfo.rider_tel ? _vm.detailsInfo.rider_tel : "暂无"))])]), _vm.detailsInfo.remark ? _c("div", {
    staticClass: "color_75 padding-t-8"
  }, [_vm._v("备注：" + _vm._s(_vm.detailsInfo.remark))]) : _vm._e()])])]), _c("div", {
    staticClass: "goods-info bg_c_white"
  }, [_c("div", {
    staticClass: "flex flex-align-center padding-tb-15"
  }, [_c("img", {
    staticStyle: {
      width: "32px",
      height: "32px"
    },
    attrs: {
      src: _vm.detailsInfo.merchant_image
    }
  }), _c("div", {
    staticClass: "padding-l-10"
  }, [_vm._v(_vm._s(_vm.detailsInfo.merchant_name))])]), _c("div", {
    staticClass: "goods-list-box"
  }, _vm._l(_vm.detailsInfo.pocket_goods_list, function (itemPocket, indexPocket) {
    return _c("div", {
      key: indexPocket,
      staticClass: "goods-list padding-tb-10"
    }, [_c("div", {
      staticClass: "color_B0 padding-tb-5"
    }, [_vm._v("口袋" + _vm._s(itemPocket.pocket))]), _vm._l(itemPocket.foods_list, function (itemGoods, indexGoods) {
      return _c("div", {
        key: indexGoods,
        staticClass: "flex flex-align-center flex-between padding-tb-10"
      }, [_c("div", {
        staticClass: "flex flex-align-center w-50"
      }, [_c("div", {
        staticClass: "flex flex-align-center"
      }, [_c("img", {
        staticStyle: {
          width: "90px",
          height: "90px"
        },
        attrs: {
          src: itemGoods.picture
        }
      }), _c("div", {
        staticClass: "goods-name"
      }, [_c("div", {
        staticClass: "font-s-16"
      }, [_vm._v(_vm._s(itemGoods.goods_name))]), _c("div", {
        staticClass: "font-s-12 padding-t-10 color_75"
      }, [_vm._v(_vm._s(itemGoods.sku_name))])])])]), _c("div", {
        staticClass: "flex flex-align-center price-box flex-between"
      }, [_c("div", [_vm._v(_vm._s(itemGoods.num))]), _c("div", {
        staticClass: "color_main"
      }, [_vm._v(_vm._s(itemGoods.price) + "P")])])]);
    })], 2);
  }), 0)]), _c("div", {
    staticClass: "foot-info bg_c_white margin-t-15 flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "flex flex-align-center"
  }, [_vm.detailsInfo.shipping_money_fee * 1 > 0 ? _c("div", {
    staticClass: "flex flex-align-center"
  }, [_c("span", [_vm._v("配送费")]), _vm.detailsInfo.delivery_fastigium_str ? _c("span", {
    staticClass: "color_main"
  }, [_vm._v(_vm._s(_vm.detailsInfo.delivery_fastigium_str))]) : _vm._e(), _c("span", {
    staticClass: "color_main"
  }, [_vm._v(_vm._s(_vm.detailsInfo.shipping_money_fee) + "P")])]) : _vm._e(), _vm.detailsInfo.manjian_money_fee * 1 > 0 ? [_c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v("满减优惠"), _c("span", {
    staticClass: "color_main"
  }, [_vm._v("-" + _vm._s(_vm.detailsInfo.manjian_money))])])] : _vm._e(), _vm.detailsInfo.package_money_fee * 1 > 0 ? [_c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v("包装费"), _c("span", {
    staticClass: "color_main"
  }, [_vm._v("-" + _vm._s(_vm.detailsInfo.package_money))])])] : _vm._e(), _vm.detailsInfo.new_customer_money_fee * 1 > 0 ? [_c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v("门店新客立减"), _c("span", {
    staticClass: "color_main"
  }, [_vm._v("-" + _vm._s(_vm.detailsInfo.new_customer_money))])])] : _vm._e(), _vm.detailsInfo.coupon_money_fee * 1 > 0 ? [_c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v("代金券"), _c("span", {
    staticClass: "color_main"
  }, [_vm._v("-" + _vm._s(_vm.detailsInfo.coupon_money))])])] : _vm._e(), _vm.detailsInfo.platform_coupon_money_fee * 1 > 0 ? [_c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v("抵用券"), _c("span", {
    staticClass: "color_main"
  }, [_vm._v("-" + _vm._s(_vm.detailsInfo.platform_coupon_money))])])] : _vm._e()], 2), _c("div", {
    staticClass: "color_main"
  }, [_vm._v(" 合计: "), _c("span", {
    staticClass: "font-s-20"
  }, [_vm._v(_vm._s(_vm.detailsInfo.total_money))])])]), _vm.showTipDialog ? _c("tooltip-dialog", {
    attrs: {
      title: "温馨提示",
      details: _vm.tipText
    },
    on: {
      cancel: function ($event) {
        _vm.showTipDialog = false;
      },
      confirm: _vm.confirm,
      closeDialog: function ($event) {
        _vm.showTipDialog = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };