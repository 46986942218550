import homeApi from '../../api/home/index';
import { mobileRegular } from '../../utils/index';
export default {
  name: 'UpdatePasswordDialog',
  data() {
    return {
      dialogVisible: true,
      disabled: true,
      params: {
        mobile: '',
        code: '',
        passWord: '',
        showMobile: false,
        showCode: false,
        showPassWord: false
      },
      mobiles: this.$store.state.user.mobiles,
      defaultCode: '63',
      // 默认区号
      areaCodeObj: this.$store.state.user.mobiles[1],
      time: 60,
      sending: false
    };
  },
  methods: {
    // 关闭弹窗事件
    closeDialog() {
      this.$emit('closeDialog');
    },
    // 返回上一级
    back() {
      this.$emit('back');
    },
    // 验证手机号
    checkPhone() {
      let mobileInfo = {
        country: this.areaCodeObj.country,
        phone: this.params.mobile
      };
      if (!this.params.mobile || !mobileRegular(mobileInfo)) {
        this.$message.warning('请检查区号或手机号是否正确');
        return false;
      }
      return true;
    },
    // 发送验证码
    async sendCode() {
      if (this.sending) {
        return;
      }
      try {
        if (this.time < 60) return;
        let checkRes = this.checkPhone();
        if (!checkRes) return;
        this.sending = true;
        await homeApi.getSmsCode({
          mobile: this.params.mobile
        });
        this.$message.success('发送成功');
        this.countTime();
      } catch (e) {
        this.sending = false;
      }
    },
    // 倒计时
    countTime() {
      clearInterval(this.timeIndex);
      this.timeIndex = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          this.time = 60;
          this.sending = false;
          clearInterval(this.timeIndex);
        }
      }, 1000);
    },
    // 选择区号
    handleAreaCode(obj) {
      this.areaCodeObj = obj;
      this.defaultCode = obj.areaCode;
    },
    // 错误提示
    errBlur() {
      if (this.params.mobile && this.params.code && this.params.passWord) {
        this.disabled = false;
      }

      // if (this.params.mobile) {
      //   if (this.params.mobile.length < 11) {
      //     return this.params.showMobile = true
      //   } else {
      //     this.params.showMobile = false
      //   }
      // }

      if (this.params.passWord) {
        var passwordreg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/;
        if (!passwordreg.test(this.params.passWord)) {
          return this.params.showPassWord = true;
        } else {
          this.params.showPassWord = false;
        }
      }

      // if (this.params.code && this.params.code.length < 11) {
      //   return this.params.showMobile = true
      // }
    },
    // 确认修改密码
    confirm() {
      if (this.params.showMobile && this.params.showCode && this.params.showPassWord) {
        return this.disabled = true;
      } else {
        this.disabled = false;
        this.$emit('confirm', this.params);
      }
    }
  }
};