/*
 * @Descripttion: 外卖首页面接口文件，使用时import对应的文件
 * @Date: 2022-03-30 11:35:58
 * @LastEditTime: 2022-04-14 11:39:19
 */
import request from '@/common/request';
import $config from '@/config';
export default {
  /**
  * @description 外卖栏目
  */
  shopTopic(params) {
    return request({
      url: '/web/shop/topic',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  report(params) {
    return request({
      url: '/apiv1/app.report',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  }
};