var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "online-pay-wrap"
  }, [_c("div", {
    staticClass: "bg_c_white",
    staticStyle: {
      padding: "48px"
    }
  }, [_c("div", {
    staticClass: "flex flex-align-center"
  }, [_c("img", {
    staticStyle: {
      width: "50px",
      height: "50px"
    },
    attrs: {
      src: require("@/assets/icons/succeed.png")
    }
  }), _c("div", {
    staticClass: "top-right"
  }, [_c("div", {
    staticClass: "flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "font-s-18"
  }, [_vm._v("订单提交成功")]), _c("div", {
    staticClass: "color_75 font-s-14"
  }, [_vm._v("应付金额： "), _c("span", {
    staticClass: "color_main",
    staticStyle: {
      "font-size": "20px"
    }
  }, [_vm._v(_vm._s(_vm.payTypeData.pay_php))]), _c("span", {
    staticClass: "color_main"
  }, [_vm._v("₱")])])]), _vm.payTypeData.always_pay == 0 ? _c("div", {
    staticClass: "color_75 padding-t-10"
  }, [_vm._v("支付剩余时间"), _c("span", {
    staticClass: "color_main font-s-14 padding-l-5"
  }, [_vm._v(_vm._s(_vm.minute) + ":" + _vm._s(_vm.second))])]) : _vm._e(), _c("div", {
    staticClass: "color_75 padding-t-10"
  }, [_vm._v(_vm._s(_vm.payTypeData.balance_rebate_tips))])])])]), _c("div", {
    staticClass: "bg_c_white margin-t-15 bottom-detail"
  }, [_c("div", {
    staticClass: "font-s-16",
    staticStyle: {
      "border-bottom": "1px solid #E8E8E8",
      "padding-bottom": "25px"
    }
  }, [_vm._v("请选择以下支付方式")]), _c("div", {
    staticStyle: {
      padding: "25px 0"
    }
  }, [_c("div", {
    staticClass: "flex flex-align-center"
  }, _vm._l(_vm.payTypeData.pay_config, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "flex-middle cursor list position-re",
      class: {
        active: _vm.payTypeActive === i
      },
      on: {
        click: function ($event) {
          return _vm.selPayType(item, i);
        }
      }
    }, [_c("img", {
      staticStyle: {
        width: "30px",
        height: "30px"
      },
      attrs: {
        src: item.icon,
        alt: ""
      }
    }), _c("div", {
      staticClass: "font-s-12"
    }, [_c("div", {
      staticClass: "padding-l-10"
    }, [_vm._v(_vm._s(item.name))]), item.payment_type == "balance" ? _c("div", {
      staticClass: "padding-l-10 color_75 padding-t-5"
    }, [_vm._v("可用余额" + _vm._s(_vm.balance) + "P")]) : _vm._e()]), _vm.payTypeActive === i ? _c("img", {
      staticClass: "position-ab pitch",
      attrs: {
        src: require("@/assets/icons/pitch@2x.png")
      }
    }) : _vm._e()]);
  }), 0)]), _vm.isShowBtn ? _c("div", {
    staticClass: "flex flex-align-center flex-end padding-t-15"
  }, [_c("div", {
    staticClass: "cancel-btn cursor",
    on: {
      click: _vm.cancelPay
    }
  }, [_vm._v("取消支付")]), _c("div", {
    staticClass: "affirm-btn cursor",
    on: {
      click: _vm.affirmPay
    }
  }, [_vm._v("确认支付")])]) : _vm._e()]), _c("el-dialog", {
    attrs: {
      title: "支付遇到问题？",
      visible: _vm.dialogVisible,
      width: "30%",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticStyle: {
      "line-height": "20px"
    }
  }, [_vm._v("温馨提示：支付完成前请不要关闭窗口。完成支付后请根据情况点击下面的按钮！")]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "btn-cancel",
    on: {
      click: _vm.accomplishPay
    }
  }, [_vm._v("已完成支付")]), _c("el-button", {
    staticClass: "btn-confirm",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.affirmPay
    }
  }, [_vm._v("去支付")])], 1)]), _c("el-dialog", {
    attrs: {
      title: "微信扫码支付",
      visible: _vm.dialogVisibleWX,
      width: "30%",
      "before-close": _vm.handleDialogVisibleWX
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisibleWX = $event;
      }
    }
  }, [_c("iframe", {
    staticStyle: {
      height: "240px"
    },
    attrs: {
      id: "iframe",
      src: _vm.wxData.qrcode,
      width: "100%",
      frameborder: "0"
    }
  }), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "btn-confirm",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.accomplishPay
    }
  }, [_vm._v("已完成支付")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };