var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "specifiction-dialog-wrap"
  }, [_c("el-dialog", {
    attrs: {
      title: "选规格",
      visible: _vm.dialogVisible,
      width: "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.closeDialog
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", [_c("div", {
    staticClass: "padding-t-10"
  }, [_vm._v("规格")]), _c("div", {
    staticClass: "list-box flex flex-algin-center flex-wrap"
  }, _vm._l(_vm.goodsInfo.sku_list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "list cursor position-re",
      class: {
        active: _vm.activeSku == item.sku_id
      },
      on: {
        click: function ($event) {
          return _vm.handleSku(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.sku_name) + " "), item.stock * 1 === 0 ? _c("div", {
      staticClass: "color_white position-ab sell-out"
    }, [_vm._v("已售罄")]) : _vm._e()]);
  }), 0)]), _vm.goodsInfo.attr_list && _vm.goodsInfo.attr_list.length > 0 ? _vm._l(_vm.goodsInfo.attr_list, function (itemAttr, indexAttr) {
    return _c("div", {
      key: indexAttr
    }, [_c("div", {
      staticClass: "padding-t-10"
    }, [_vm._v(_vm._s(itemAttr.name))]), _c("div", {
      staticClass: "list-box flex flex-algin-center flex-wrap"
    }, _vm._l(itemAttr.values, function (item, i) {
      return _c("div", {
        key: i,
        staticClass: "list cursor",
        class: {
          active: item.checked
        },
        on: {
          click: function ($event) {
            return _vm.handleAttr(indexAttr, itemAttr, item);
          }
        }
      }, [_vm._v(_vm._s(item.attr_value))]);
    }), 0)]);
  }) : _vm._e()], 2), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("div", {
    staticClass: "sel-m"
  }, [_c("span", {
    staticClass: "color_75"
  }, [_vm._v("已选规格:")]), _c("span", {
    staticClass: "padding-l-5"
  }, [_vm._v(_vm._s(_vm.selSpeci))])]), _c("div", {
    staticClass: "dialog-footer flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "font-s-12"
  }, [_c("span", [_vm._v("总计")]), _c("span", {
    staticClass: "padding-l-5 color_main"
  }, [_vm._v("₱")]), _c("span", {
    staticClass: "color_main font-s-18"
  }, [_vm._v(_vm._s(_vm.totalPrice))])]), _vm.isShowNum ? _c("div", {
    staticClass: "flex-middle num-main"
  }, [_c("div", {
    staticClass: "flex-middle icon-box cursor h-100",
    on: {
      click: function ($event) {
        return _vm.handleNum("minus", _vm.goodsInfo);
      }
    }
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/minus@2x.png")
    }
  })]), _c("div", {
    staticClass: "text-center num"
  }, [_vm._v(_vm._s(_vm.speciNum))]), _c("div", {
    staticClass: "flex-middle icon-box cursor h-100",
    on: {
      click: function ($event) {
        return _vm.handleNum("add", _vm.goodsInfo);
      }
    }
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/addC@2x.png")
    }
  })])]) : _c("el-button", {
    staticClass: "btn-confirm flex flex-align-center",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addCart
    }
  }, [_c("span", {
    staticClass: "font-s-18 padding-r-5"
  }, [_vm._v("+")]), _vm._v(" 加入购物车 ")])], 1)])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };