var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-home-wrap"
  }, [_c("div", {
    staticClass: "info-wrap bg_c_white margin-b-15 flex flex-align-center"
  }, [_c("div", {
    staticClass: "flex-middle flex-column user"
  }, [_vm.profile.avatar ? _c("img", {
    staticClass: "avatar bor-r-50",
    attrs: {
      src: _vm.profile.avatar
    }
  }) : _c("img", {
    staticClass: "avatar bor-r-50",
    attrs: {
      src: _vm.$config.DEFAULT_AVATAR
    }
  }), _c("div", {
    staticClass: "padding-t-10 f-s-20"
  }, [_vm._v(_vm._s(_vm.profile.nickname ? _vm.profile.nickname : _vm.profile.username))])]), _c("div", {
    staticClass: "flex user-right"
  }, [_c("div", {
    staticClass: "flex flex-align-center cursor module",
    on: {
      click: function ($event) {
        return _vm.toPage("score");
      }
    }
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/jifen@2x.png")
    }
  }), _c("div", [_c("div", [_vm._v("会员积分")]), _c("div", {
    staticClass: "details f-s-20 padding-t-10"
  }, [_vm._v(_vm._s(_vm.profile.score))])])]), _c("div", {
    staticClass: "flex flex-align-center cursor module",
    on: {
      click: function ($event) {
        return _vm.toPage("balance");
      }
    }
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/yue@2x.png")
    }
  }), _c("div", [_c("div", [_vm._v("账户余额")]), _vm.profile.meta ? _c("div", {
    staticClass: "details f-s-20 padding-t-10"
  }, [_vm._v(_vm._s(_vm.profile.meta.balance) + "P")]) : _vm._e()])]), _c("div", {
    staticClass: "flex flex-align-center cursor module",
    on: {
      click: function ($event) {
        return _vm.toPage("coupon");
      }
    }
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/youhuiquan@2x.png")
    }
  }), _c("div", [_c("div", [_vm._v("优惠券")]), _vm.profile.meta ? _c("div", {
    staticClass: "details f-s-20 padding-t-10"
  }, [_vm._v(_vm._s(_vm.profile.meta.coupon))]) : _vm._e()])])])]), _c("div", {
    staticClass: "order-wrap bg_c_white"
  }, [_c("div", {
    staticClass: "flex flex-align-center flex-between padding-b-5"
  }, [_c("div", {
    staticClass: "title color_75"
  }, [_vm._v("我的订单")]), _c("div", {
    staticClass: "color_75 font-s-16 cursor",
    on: {
      click: function ($event) {
        return _vm.toPage("takeout");
      }
    }
  }, [_vm._v("更多")])]), _vm.list.length > 0 ? _c("div", {
    staticClass: "padding-tb-15"
  }, [_c("order-list", {
    attrs: {
      sourceModule: "home",
      list: _vm.list
    },
    on: {
      toOrderDetails: _vm.toOrderDetails,
      openDailog: _vm.openTooltipDailog,
      refresh: _vm.refreshList
    }
  })], 1) : _c("div", {
    staticClass: "color_75 bg_c_white padding-tb-15 text-center font-s-12"
  }, [_vm._v("暂无数据")])]), _c("div", {
    staticClass: "collect-wrap bg_c_white margin-t-15"
  }, [_c("div", {
    staticClass: "title-box flex flex-align-center flex-between padding-b-5"
  }, [_c("div", {
    staticClass: "title color_75"
  }, [_vm._v("我的收藏")]), _c("div", {
    staticClass: "color_75 font-s-16 cursor",
    on: {
      click: function ($event) {
        return _vm.toPage("collect");
      }
    }
  }, [_vm._v("更多")])]), _vm.shopInfo.shops && _vm.shopInfo.shops.length == 0 ? _c("div", {
    staticClass: "color_75 bg_c_white text-center font-s-12",
    staticStyle: {
      padding: "15px 0 45px 0"
    }
  }, [_vm._v("暂无数据")]) : _c("div", {
    staticClass: "list"
  }, [_c("recommend-card", {
    attrs: {
      source: "collect",
      sourceModule: "home",
      list: _vm.shopInfo.shops
    },
    on: {
      handleCard: _vm.handleCard
    }
  })], 1)]), _vm.showTipDialog ? _c("tooltip-dialog", {
    attrs: {
      title: "温馨提示",
      details: "是否删除该订单？"
    },
    on: {
      cancel: _vm.closeTisDialog,
      confirm: _vm.delOrder,
      closeDialog: _vm.closeTisDialog
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };