import { Loader } from "@googlemaps/js-api-loader";
export default {
  name: 'googleMap',
  data() {
    return {
      h: '60vh',
      w: '100%',
      lw: '',
      keyword: '',
      obj: {},
      city: '',
      location: ''
    };
  },
  mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyBYBUz5uZNGGlBnAUkhV9qOoGCukTBp_eI",
      // google控制台中的apikey
      version: "weekly",
      libraries: ["places", ""]
    });

    // var h = window.innerHeight || document.documentElement.clientHeight
    // var w = window.innerWidth || document.documentElement.clientWidth
    // this.h = h + 'px'
    // this.w = (w - 400) / 2 + 'px'
    // this.lw = (w - 400) / 2 + 400 + 20 + 'px'

    loader.load().then(() => {
      navigator.geolocation.getCurrentPosition(position => {
        this.init(position);
      }, error => {
        let obj = {
          coords: {
            latitude: 14.551466,
            longitude: 121.026827
          }
        };
        this.init(obj);
      });
    });
  },
  methods: {
    init(position) {
      var that = this;
      this.map = new google.maps.Map(this.$refs.allmap, {
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        scrollwheel: true,
        zoom: 13
      });
      this.marker = new google.maps.Marker({
        position: {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        },
        map: this.map,
        draggable: true
      });
      // 获取地理编码和反编码类
      var geo = new google.maps.Geocoder();
      console.log(that.marker.position);
      // 传入拖拽结束点的地址坐标对象
      geo.geocode({
        location: that.marker.position
      }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          var address_components = results[0].address_components;
          var pointArea = {};
          // 存储坐标地理信息
          pointArea.street = address_components[0] ? address_components[0].long_name : '';
          pointArea.district = address_components[1] ? address_components[1].long_name : '';
          pointArea.city = address_components[2] ? address_components[2].long_name : '';
          pointArea.province = address_components[3] ? address_components[3].long_name : '';

          // 根據經緯度獲取地址信息
          var obj = {
            province: pointArea.province,
            city: pointArea.city,
            district: pointArea.district,
            street: pointArea.street,
            lng: position.coords.longitude,
            lat: position.coords.latitude
          };
          console.log(obj);
          that.obj = obj;
          console.log(that.obj);
          that.city = obj.city;
          that.location = results[0].formatted_address;
        } else {
          this.$message.error('获取定位失败');
        }
      });
      console.log(that.marker);
      google.maps.event.addListener(that.marker, 'dragend', MouseEvent => {
        var lat = MouseEvent.latLng.lat().toFixed(6);
        var lng = MouseEvent.latLng.lng().toFixed(6);
        console.log(lat, lng);
        // 获取地理编码和反编码类
        var geo = new google.maps.Geocoder();
        console.log(MouseEvent.latLng);
        // 传入拖拽结束点的地址坐标对象
        geo.geocode({
          location: MouseEvent.latLng
        }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            var address_components = results[0].address_components;
            var pointArea = {};
            console.log(results[0]);
            // 存储坐标地理信息
            pointArea.street = address_components[0] ? address_components[0].long_name : '';
            pointArea.district = address_components[1] ? address_components[1].long_name : '';
            pointArea.city = address_components[2] ? address_components[2].long_name : '';
            pointArea.province = address_components[3] ? address_components[3].long_name : '';

            // 根據經緯度獲取地址信息
            var obj = {
              province: pointArea.province,
              city: pointArea.city,
              district: pointArea.district,
              street: pointArea.street,
              lng: lng,
              lat: lat
            };
            console.log(obj);
            that.obj = obj;
            that.city = obj.city;
            that.location = results[0].formatted_address;
          } else {
            this.$message.error('获取定位失败');
          }
        });
      });
      var autocomplete = new google.maps.places.Autocomplete(that.$refs.keywordText);
      autocomplete.addListener('place_changed', function () {
        var place = autocomplete.getPlace();
        console.log(place);
        that.marker.setPosition(place.geometry.location);
        that.map.setCenter(place.geometry.location);
        var address_components = place.address_components;
        var lng = place.geometry.location.lng().toFixed(6);
        var lat = place.geometry.location.lat().toFixed(6);
        var pointArea = {};
        // 存储坐标地理信息
        pointArea.street = address_components[0] ? address_components[0].long_name : ''; //街道
        pointArea.district = address_components[1] ? address_components[1].long_name : ''; //区
        pointArea.city = address_components[2] ? address_components[2].long_name : ''; //市
        pointArea.province = address_components[3] ? address_components[3].long_name : ''; //省

        // 根據經緯度獲取地址信息
        var obj = {
          province: pointArea.province,
          city: pointArea.city,
          district: pointArea.district,
          street: pointArea.street,
          lng: lng,
          lat: lat
        };
        console.log(obj);
        that.obj = obj;
        that.keyword = obj.street + ',' + obj.district + ',' + obj.city + ',' + obj.province;
        that.city = obj.province;
        that.location = place.formatted_address;
      });
    },
    confirm() {
      const data = {
        lng: this.obj.lng,
        lat: this.obj.lat,
        address: this.location
      };
      this.$emit('confirmMap', data);
    },
    cancel() {
      this.$emit('cancelMap');
    }
  }
};