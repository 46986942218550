var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex carousel-wrap padding-b-15",
    staticStyle: {
      height: "28vw"
    }
  }, [_c("el-carousel", {
    staticClass: "slider-wrap position-re",
    attrs: {
      interval: 5000,
      "indicator-position": "inside",
      height: "100%",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList || [], function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "slider-item",
      nativeOn: {
        click: function ($event) {
          return _vm.handleCarousel(item);
        }
      }
    }, [_c("img", {
      staticClass: "slider-img w-100 h-100",
      attrs: {
        src: item.image
      }
    })]);
  }), 1), _c("div", {
    staticClass: "h-100 slider-right",
    class: _vm.source === "home" ? "bg_75" : "bg_c_white shadow"
  }, [_c("div", {
    staticClass: "slider-right-top flex-middle"
  }, [!_vm.token ? [_c("img", {
    staticClass: "slider-right-avatar",
    attrs: {
      src: _vm.$config.DEFAULT_AVATAR
    }
  }), _c("div", {
    class: _vm.source === "home" ? "color_white" : "color_75"
  }, [_c("div", [_vm._v("欢迎来到筷子生活!")]), _c("div", {
    staticClass: "margin-t-5 cursor"
  }, [_c("span", {
    on: {
      click: function ($event) {
        return _vm.handleDialog("login");
      }
    }
  }, [_vm._v("登录")]), _vm._v("｜ "), _c("span", {
    on: {
      click: function ($event) {
        return _vm.handleDialog("register");
      }
    }
  }, [_vm._v("免费注册")])])])] : [_vm.profile.avatar ? _c("img", {
    staticClass: "slider-right-avatar",
    attrs: {
      src: _vm.profile.avatar
    }
  }) : _c("img", {
    staticClass: "slider-right-avatar",
    attrs: {
      src: _vm.$config.DEFAULT_AVATAR
    }
  }), _c("div", {
    class: _vm.source === "home" ? "color_white" : "color_75"
  }, [_c("div", [_vm._v("Hi ~ " + _vm._s(_vm.profile.nickname ? _vm.profile.nickname : _vm.profile.username))]), _c("div", {
    staticClass: "margin-t-5 cursor",
    on: {
      click: _vm.logout
    }
  }, [_vm._v("退出")])])]], 2), _c("div", {
    staticClass: "flex flex-wrap menu-box"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "w-50 flex flex-middle flex-column cursor",
      class: _vm.source === "home" ? "home-menu" : "menu",
      on: {
        mouseover: function ($event) {
          return _vm.handleMenu(item);
        },
        mouseout: function ($event) {
          return _vm.handleMenu(item);
        },
        click: function ($event) {
          return _vm.skip(item);
        }
      }
    }, [!item.show_menu ? _c("img", {
      staticClass: "menu-icon",
      attrs: {
        src: item.image
      }
    }) : _vm._e(), _vm.source === "home" && item.show_menu ? _c("img", {
      staticClass: "menu-icon",
      attrs: {
        src: item.image_active
      }
    }) : _vm._e(), _c("div", {
      staticClass: "padding-t-10"
    }, [_vm._v(_vm._s(item.title))])]);
  }), 0)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };