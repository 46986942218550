import homeApi from '../../api/home/index';
export default {
  name: 'PersonalData',
  data() {
    return {
      sexArr: [],
      sexList: [{
        id: 1,
        name: '男'
      }, {
        id: 2,
        name: '女'
      }],
      nickname: '',
      sex: ''
    };
  },
  computed: {
    profile() {
      return this.$store.state.user.profile;
    }
  },
  mounted() {
    this.$set(this.sexArr, 0, this.profile.sex == 1 ? '男' : '女');
    this.nickname = this.profile.nickname;
    this.sex = this.profile.sex;
  },
  methods: {
    // 选择性别
    sexChange() {
      if (this.sexArr.length > 1) {
        this.sexArr.shift();
      }
      this.sex = this.sexArr[0] === '男' ? '1' : '2';
    },
    // 编辑个人资料
    async editUserInfo() {
      const option = {
        nickname: this.nickname,
        sex: this.sex
      };
      try {
        const data = await homeApi.setProfile(option);
        if (data.result == 1) {
          this.$message.success("修改个人资料成功");
          this.$store.dispatch('user/userInfo');
        }
      } catch (error) {}
    }
  }
};