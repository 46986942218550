var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "balance-wrap"
  }, [_c("title-panel", {
    attrs: {
      title: _vm.title,
      val: _vm.getBalanceNum + "₱",
      icon: require("@/assets/icons/yue2@2x.png"),
      isBtn: _vm.type === "balance" ? true : false
    },
    on: {
      confirm: _vm.openRecharge,
      back: _vm.showBalance
    }
  }), _c("div", {
    staticClass: "margin-t-15"
  }, [_vm.type === "balance" ? _c("detail-list", {
    ref: "detailLlistCom",
    attrs: {
      title: "余额明细"
    },
    on: {
      balanceNum: _vm.balanceNumFun
    }
  }) : _c("recharge", {
    on: {
      back: _vm.showBalance
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };