export default {
  name: 'TooltipDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    details: {
      type: String,
      default: ''
    },
    loadingBtnText: {
      type: String,
      default: '确 定'
    },
    disabledBtn: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    closeDialog() {
      this.$emit('cancel');
    }
  }
};