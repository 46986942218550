import TitlePanel from '@/components/serve/title-panel.vue';
import DetailList from '@/components/serve/detail-list.vue';
import Recharge from '@/components/serve/recharge.vue';
export default {
  name: 'Balance',
  components: {
    TitlePanel,
    DetailList,
    Recharge
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      title: '账户余额',
      type: 'balance',
      balanceNum: 0.00
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    profile() {
      return this.$store.state.user.profile;
    },
    getBalanceNum() {
      return this.balanceNum;
    }
  },
  created() {
    if (this.$route.query.type) {
      this.openRecharge();
    }
  },
  methods: {
    // 显示披索充值模块
    openRecharge() {
      this.title = '披索充值';
      this.type = 'recharge';
      this.$emit('backTop');
    },
    // 显示账户余额模块
    showBalance() {
      this.title = '账户余额';
      this.type = 'balance';
      this.$emit('backTop');
    },
    balanceNumFun(val) {
      this.balanceNum = val;
    }
  }
};