import takeoutApi from "./api/waimai/index";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  created() {
    this.report();
    this.$router.beforeEach((to, from, next) => {
      this.report();
      next();
    });
  },
  methods: {
    report() {
      takeoutApi.report([{
        name: "onForeground",
        time: parseInt(new Date().getTime().toString(), 10),
        data: ""
      }]);
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  }
};