var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "integral-wrap"
  }, [_c("title-panel", {
    attrs: {
      title: "会员积分",
      val: _vm.$store.state.user.profile.score,
      icon: require("@/assets/icons/jifen2@2x.png"),
      isBtn: false
    }
  }), _c("div", {
    staticClass: "margin-t-15"
  }, [_c("detail-list", {
    attrs: {
      title: "积分明细"
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };