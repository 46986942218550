import shopDetailsApi from '../../api/waimai/shopDetails';
export default {
  name: 'EvaluateList',
  props: {
    shopId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      evaluateList: [],
      page: 1,
      pageTotal: 0
    };
  },
  mounted() {
    this.getEvaluateInfo();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 获取评价列表数据
    async getEvaluateInfo() {
      try {
        const option = {
          merchant_id: this.shopId,
          page: this.page,
          page_size: 14
        };
        const data = await shopDetailsApi.evaluateLists(option);
        this.evaluateList = this.evaluateList.concat(data.list);
        this.pageTotal = data.count * 1;
      } catch (error) {}
    },
    // 触底加载更多
    handleScroll(e) {
      // 获取滚动的距离
      let scrollTop = e.target.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      let clientHeight = e.target.clientHeight;
      // 获取屏幕(浏览器)高度
      let scrollHeight = e.target.scrollHeight;
      // 如果滚动到接近底部，自动加载下一页
      if (this.page < this.pageTotal && scrollTop + clientHeight - scrollHeight >= 0) {
        // 事件处理
        this.page++;
        this.getEvaluateInfo();
      }
    }
  }
};