import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import rechargeApi from '../../api/waimai/recharge';
export default {
  name: 'Recharge',
  data() {
    const validateMoney = (rule, value, callback) => {
      if (value < this.rechargeInfo.min_amount * 1) {
        this.isRecharge = false;
        callback(new Error(`充值金额至少${this.rechargeInfo.min_amount}₱`));
      }
      if (value > this.rechargeInfo.max_amount * 1) {
        this.isRecharge = false;
        callback(new Error(`最高充值金额不能够大于${this.rechargeInfo.max_amount}₱`));
      }
      if (value >= this.rechargeInfo.min_amount * 1 && value <= this.rechargeInfo.max_amount * 1) {
        this.isRecharge = true;
      }
    };
    return {
      moneyActive: 0,
      moneyList: [],
      addressActive: 1,
      dialogVisible: false,
      money: '',
      ruleForm: {
        money: ''
      },
      rules: {
        money: [{
          required: true,
          message: '请输入充值金额'
        }, {
          type: 'number',
          message: '金额必须为数字值'
        }, {
          validator: validateMoney,
          trigger: "blur"
        }]
      },
      dialogAddress: false,
      moneyTime: [],
      rechargeInfo: {},
      addressId: '',
      isRecharge: false
    };
  },
  created() {
    this.getRechargeInfo();
  },
  methods: {
    // 选择充值的金额
    selMoney(val, i) {
      this.money = val;
      this.moneyActive = i;
      if (i == 5) {
        if (this.money != '其他金额') {
          this.ruleForm.money = this.money;
        }
        this.dialogVisible = true;
      }
    },
    // 关闭充值金额弹窗
    closeDialog() {
      this.ruleForm.money = '';
      this.dialogVisible = false;
      // 清除表单域下的提示信息
      this.$refs.ruleForm.clearValidate();
    },
    // 确认充值
    confirmRecharge() {
      if (this.ruleForm.money === '') {
        return this.$message.error('请输入充值金额');
      }
      // if (this.ruleForm.money < (this.rechargeInfo.min_amount*1)) {
      //   return this.$message.error(`充值金额至少${this.rechargeInfo.min_amount}₱`)
      // }
      // if (this.ruleForm.money > (this.rechargeInfo.max_amount*1)) {
      //   return this.$message.error(`最高充值金额不能够大于${this.rechargeInfo.max_amount}₱`)
      // }

      if (this.isRecharge) {
        this.rechargeInfo.amount_list[this.moneyActive] = this.ruleForm.money;
        this.money = this.ruleForm.money;
        this.dialogVisible = false;
      }
    },
    handleCascader(time) {
      this.moneyTime = time[0] + ' ' + time[1];
      this.getRechargeInfo();
    },
    // 获取地址id
    getAddressId(id) {
      this.addressId = id;
      this.getRechargeInfo();
    },
    // 获取披索充值信息
    async getRechargeInfo() {
      const option = {
        address_id: this.addressId,
        shipping_time: this.moneyTime
      };
      try {
        this.rechargeInfo = await rechargeApi.balanceRecharge(option);
        this.addressId = this.rechargeInfo.address_info.address_id;
        this.rechargeInfo.amount_list.push(this.money && this.moneyActive == 5 ? this.money : '其他金额');

        // 设置默认充值金额
        this.money = this.rechargeInfo.amount_list[0];
        // 设置默认取现时间
        this.moneyTime = this.rechargeInfo.shipping_time;
      } catch (error) {}
    },
    // 创建充值订单
    async createRecharge() {
      try {
        const option = {
          shipping_time: this.moneyTime,
          address_id: this.addressId,
          recharge_money: this.money,
          type: '2'
        };
        await rechargeApi.doRecharge(option);
        this.$message.success('订单提交成功，请耐心等待快递员上门服务');
        this.$emit('back');
      } catch (error) {}
    }
  }
};