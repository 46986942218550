import { render, staticRenderFns } from "./title-panel.vue?vue&type=template&id=f1f1e022&scoped=true"
import script from "./title-panel.vue?vue&type=script&lang=js"
export * from "./title-panel.vue?vue&type=script&lang=js"
import style0 from "./title-panel.vue?vue&type=style&index=0&id=f1f1e022&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1f1e022",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\daoxing\\frontent\\takeout\\www\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f1f1e022')) {
      api.createRecord('f1f1e022', component.options)
    } else {
      api.reload('f1f1e022', component.options)
    }
    module.hot.accept("./title-panel.vue?vue&type=template&id=f1f1e022&scoped=true", function () {
      api.rerender('f1f1e022', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/serve/title-panel.vue"
export default component.exports