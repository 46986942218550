import businessHeader from '@/components/bussness-header';
import businessFooter from '@/components/business-footer';
export default {
  name: 'Layout',
  components: {
    businessHeader,
    businessFooter
  },
  data() {
    return {
      iconName: 'service3@2x'
    };
  },
  mounted() {
    if (this.$store.state.user.profile && !this.isMobile()) {
      this.$refs.sidebar.initIm();
    }
    if (this.isMobile()) {
      let footerA = document.querySelector('.business-footer-content-a');
      document.body.style.minWidth = 'unset';
      document.querySelector('.business-footer-content').style.lineHeight = 'unset';
      footerA.style.display = 'block';
      footerA.style.marginTop = '3.5vw';
    }
  },
  methods: {
    // 联系客服
    contactService() {},
    // 经过侧方栏按钮事件
    handleHover() {
      this.iconName = 'service2@2x';
    },
    // 离开侧方栏按钮事件
    handleOut() {
      this.iconName = 'service3@2x';
    },
    // 判断是否移动端
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  }
};