import orderApi from '../../api/waimai/order';
export default {
  name: 'RechargeOrder',
  data() {
    return {
      orderState: [{
        id: 0,
        title: '全部'
      }, {
        id: 2,
        title: '进行中'
      }, {
        id: 3,
        title: '已完成'
      }, {
        id: 1,
        title: '已关闭'
      }],
      stateActive: 0,
      list: []
    };
  },
  created() {
    this.getCashList();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 选择订单来源
    selOrderSource(id) {
      if (id == 'takeout') {
        this.orderSourceActive = id;
      } else {
        this.$message('功能开发中');
      }
    },
    // 选择订单状态
    handleState(id) {
      this.stateActive = id;
      this.list = [];
      this.page = 1;
      this.pageTotal = 0;
      this.getCashList();
    },
    // 更新订单列表
    refreshList() {
      this.list = [];
      this.page = 1;
      this.pageTotal = 0;
      this.getCashList();
    },
    // 用户订单列表
    async getCashList() {
      try {
        const option = {
          type: '1',
          // 1-现金充值 2-现金提现
          status: this.stateActive
          // page: this.page,
          // page_size: '15'
        };
        const data = await orderApi.cashList(option);
        this.list = this.list.concat(data.list);
        // this.pageTotal = data.last_page
      } catch (error) {}
    },
    // 触底加载更多
    handleScroll(e) {
      // 获取滚动的距离
      let scrollTop = e.target.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      let clientHeight = e.target.clientHeight;
      // 获取屏幕(浏览器)高度
      let scrollHeight = e.target.scrollHeight;
      // 如果滚动到接近底部，自动加载下一页
      if (this.page < this.pageTotal && scrollTop + clientHeight - scrollHeight >= 0) {
        // 事件处理
        this.page++;
        this.getCashList();
      }
    }
  }
};