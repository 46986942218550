const state = {
  screenInfo: {}
};
const mutations = {
  SET_SCREEN_INFO: (state, screenInfo) => {
    state.screenInfo = screenInfo;
  }
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};