export default {
  name: 'EditAddressDialog',
  props: {
    addreddTitle: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: () => {}
    },
    isChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: true,
      addressConfirm: false
    };
  },
  methods: {
    sexChange() {
      this.$emit('sexChange');
    },
    wayChange() {
      this.$emit('wayChange');
    },
    save() {
      this.$emit('save');
    },
    openDialogAddressList() {
      this.$emit('openDialogAddressList');
    },
    beforeClose(done) {
      if (this.params.need_edit && this.isChange) {
        this.addressConfirm = true;
      } else {
        done();
      }
    },
    closeDialog() {
      this.$emit('close');
    }
  }
};