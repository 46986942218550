import { getToken, setToken, removeToken } from '@/utils/auth';
import { sessStore } from '@/utils';
import homeApi from '../../api/home/index';
const state = {
  token: getToken(),
  profile: sessStore.get('profile') || {},
  mobiles: [{
    country: 'CN',
    name: '中国',
    areaCode: '86'
  }, {
    country: 'PH',
    name: '菲律宾',
    areaCode: '63'
  }],
  noticeCounts: 0,
  noticeCounts: 0,
  detailsGoodsList: [],
  cartListDrawer: [],
  allGoodsLen: 0,
  // 全部商品数量
  pocketGoodsLen: 0,
  // 口袋商品数量
  goodsTotal: 0,
  // 总价
  totalPriceCart: 0,
  // 购物车总价
  latitude: {},
  isOnlinePay: false // 显示/隐藏选择在线支付
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_PROFILE: (state, profile) => {
    state.profile = profile;
    sessStore.set('profile', profile);
  },
  SET_NOTICECOUNTS: (state, noticeCounts) => {
    state.noticeCounts = noticeCounts;
  },
  // 商品详情商品列表数据
  SET_DETAILSGOODSLIST: (state, data) => {
    state.detailsGoodsList = data;
  },
  // 购物车列表数据
  SET_CARTLISTRAWER: (state, data) => {
    state.cartListDrawer = data;
  },
  // 总价
  SET_TOTALPRICECART(state, data) {
    state.totalPriceCart = data;
  },
  // 选中口袋的商品数量
  SET_POCKETGOODSLEN: (state, data) => {
    state.pocketGoodsLen = data;
  },
  // 全部商品数量
  SET_ALLGOODSLEN: (state, data) => {
    state.allGoodsLen = data;
  },
  SET_LATITUDE: (state, data) => {
    state.latitude = data;
  },
  SET_ISONLINEPAY: (state, data) => {
    state.isOnlinePay = data;
  }
};
const actions = {
  // 登录
  login({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      homeApi.login(userInfo).then(data => {
        commit('SET_TOKEN', data.token);
        this.dispatch('user/userInfo');
        // commit('SET_PROFILE', data.user)
        setToken(data.token);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 验证码登录
  loginSms({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      homeApi.loginSms(userInfo).then(data => {
        commit('SET_TOKEN', data.token);
        this.dispatch('user/userInfo');
        setToken(data.token);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 注册
  register({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      homeApi.register(userInfo).then(data => {
        commit('SET_TOKEN', data.token);
        this.dispatch('user/userInfo');
        setToken(data.token);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 用户个人信息
  userInfo({
    commit
  }) {
    return new Promise((resolve, reject) => {
      homeApi.profile().then(data => {
        commit('SET_PROFILE', data);
        commit('SET_ISONLINEPAY', false);
        commit('SET_LATITUDE', data.default_address);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  // 退出登录
  logout({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_PROFILE', {});
      removeToken();
      resolve();
    });
  },
  // 清除token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};