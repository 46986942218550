/*
 * @Descripttion: 路由页面
 * @Date: 2021-04-22 14:08:42
 * @LastEditTime: 2023-03-29 17:04:28
 */
/*eslint-disable*/
import Vue from 'vue';
import Router from 'vue-router';
import $config from '@/config';
Vue.use(Router);
import Layout from '@/views/layout';
import waimai from './waimai';
import shop from './shop';
import taotao from './taotao';
import league from './league';
import home from './home';

/**
* meta: object
* @params(isLogin): 是否需要登录
* @params(bg): 当前路由body背景色
**/
const router = new Router({
  routes: [{
    path: '/404',
    name: '404',
    meta: {
      title: '未找到页面'
    },
    component: () => import('@/views/404')
  }, {
    path: '/',
    component: Layout,
    redirect: '/waimai/index',
    // /index 原来的首页
    children: [
    //   {
    //     path: 'index',
    //     name: 'Index',
    //     component: () => import('@/views/index'),
    //     meta: { title: '首页' }
    //   },
    {
      path: 'search/result',
      name: 'SearchResult',
      component: () => import('@/views/search/result'),
      meta: {
        title: '搜索结果'
      }
    }, {
      path: 'user/index',
      name: 'User',
      component: () => import('@/views/user/index'),
      meta: {
        title: '个人中心'
      }
    }]
  }, ...home, ...waimai, ...taotao, ...shop, ...league,, {
    path: '*',
    redirect: {
      name: '404'
    }
  }],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

// 路由拦截器
router.beforeEach(function (to, from, next) {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | ' + $config.TITLE;
  }
  next();
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};
router.afterEach(function (to) {});
export default router;