/*
 * @Descripttion: 店铺详情接口文件，使用时import对应的文件
 * @Date: 2022-07-12 16:21:45
 * @LastEditTime: 2022-08-05 18:29:06
 */
import request from '@/common/request';
import $config from '@/config';
export default {
  /**
  * @description 获取店铺详情数据
  */
  merchantDetail(params) {
    return request({
      url: '/web/merchant/merchantDetail',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 收藏外卖商家
  */
  favMerchant(params) {
    return request({
      url: '/api/tuser/favMerchant',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 商家详情评价列表
  */
  evaluateLists(params) {
    return request({
      url: '/web/merchant/evaluateLists',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  }
};