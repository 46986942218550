export default {
  name: 'BottomTip',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    },
    text: {
      type: String,
      default: '暂无数据'
    }
  },
  data() {
    return {};
  },
  methods: {}
};