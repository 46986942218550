export default {
  name: 'RecommendCard',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    },
    source: {
      type: String,
      default: 'home'
    },
    sourceModule: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      shopList: []
    };
  },
  watch: {
    list: {
      handler(val) {
        this.shopList = val;
      }
    }
  },
  methods: {
    handleCard(obj) {
      this.$emit('handleCard', obj.shop ? obj.shop.id : obj.id);
    }
  }
};