import "core-js/modules/es.array.push.js";
export default {
  name: 'SearchModule',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchInput: this.$route.query.content || '' // 搜索值
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    profile() {
      return this.$store.state.user.profile;
    },
    latitude() {
      return this.$store.state.user.latitude;
    }
  },
  methods: {
    search() {
      this.$emit('search', this.searchInput);
    },
    toAddress() {
      if (this.token) {
        this.$router.push({
          path: '/user/index',
          query: {
            currentCollapse: 'address',
            isCut: true
          }
        });
      } else {
        this.$bus.$emit('openDialog', 'login');
      }
    }
  }
};