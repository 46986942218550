import "core-js/modules/es.error.cause.js";
export default {
  props: {
    title: {
      type: String,
      default: '请输入支付密码'
    }
  },
  name: 'PayPasswordDialog',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
        this.disabled = true;
      } else {
        if (this.ruleForm.newPassword !== "") {
          this.$refs.ruleForm.validateField("newPassword");
        }
        callback();
        this.disabled = true;
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
        this.disabled = true;
      } else if (value !== this.ruleForm.oldPassword) {
        callback(new Error("两次输入密码不一致!"));
        this.disabled = true;
      } else {
        callback();
        this.disabled = false;
      }
    };
    const validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
        this.disabled = true;
      } else {
        callback();
        this.disabled = false;
      }
    };
    return {
      dialogVisible: true,
      disabled: true,
      ruleForm: {},
      rules: {
        oldPassword: [{
          required: true,
          trigger: "blur",
          message: "请输入密码"
        }, {
          min: 6,
          message: "至少6位数字",
          trigger: "blur"
        }, {
          validator: validatePass,
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }, {
          min: 6,
          message: "至少6位数字",
          trigger: "blur"
        }, {
          validator: validatePass2,
          trigger: "blur"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "请输入密码"
        }, {
          min: 6,
          message: "至少6位数字",
          trigger: "blur"
        }, {
          validator: validatePass3,
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    // 关闭弹窗事件
    closeDialog() {
      this.$emit('closeDialog');
    },
    // 确认修改密码
    confirm() {
      if (this.disabled) return;
      this.$emit('confirm', this.ruleForm);
    }
  }
};