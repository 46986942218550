/*
 * @Descripttion: 入驻合作主页
 * @Date: 2022-04-06 13:37:31
 * @LastEditTime: 2022-04-11 20:51:05
 */
import Layout from '@/views/layout';
export default [{
  path: '/league',
  component: Layout,
  redirect: '/league/index',
  children: [{
    path: 'index',
    name: 'Index',
    component: () => import('@/views/league/index'),
    meta: {
      title: '入驻合作'
    }
  }]
}];