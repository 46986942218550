/*
 * @Description: 借助webpack，使用 require.context() 方法来创建自己的（模块）上下文，从而实现自动动态require组件，
 * 这个方法需要3个参数：要搜索的文件夹目录（查找需要文件的相对路径），是否还应该搜索它的子目录，以及一个匹配文件的正则表达式。
 * 在 main.js 中 import 'components/index.js'，然后就可以随时随地使用这些基础组件，无需手动引入了
 * 组件命名方式：
 * 1. 单驼峰：baseButton.vue 在使用时通过 <base-button></base-button> 使用
 * 2. 双驼峰：ComButton.vue，使用 com-button
 * 3. 文件夹/组件名.vue: com-button/com-button.vue，使用 com-button
 * @Date: 2020-12-23 17:28:31
 * @LastEditTime: 2022-04-14 11:23:41
 * 
 */
// # 自定义组件 - COMPONENTS
// 命名规则：
// - 如：`com-header/com-header.vue`或`com-header.vue`, 可在组件中直接通过 `<com-header></com-header>`调用，不需要另外进行注册。
// - 建议尽可能使用`文件夹/同名文件.vue`的格式命名，使用到的 js 逻辑代码可以放在同级文件夹中
// - 所有组件都必须有`name`属性，且不能和已有`name`属性同名

import Vue from 'vue';
const requireComponent = require.context('.', true, /\.vue$/
// 找到components文件夹下以.vue命名的文件
);

// 循环获取到的文件，可在循环时处理文件名
requireComponent.keys().forEach(fileName => {
  // 获取组件配置(组件模板)
  const componentConfig = requireComponent(fileName);
  // 因为得到的filename格式是: './baseButton.vue', 所以这里我们去掉头和尾，只保留真正的文件名
  const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '').split('/'); // split('/')兼容 ./com-button/com-button.vue 结构
  // console.log(componentName, '基础组件文件获取测试')
  // 将组件在循环中注册到全局
  Vue.component(componentName[1] || componentName[0], componentConfig.default || componentConfig);
});