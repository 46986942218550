var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "search-module-wrap flex flex-align-center"
  }, [_c("div", {
    staticClass: "address flex flex-align-center cursor",
    on: {
      click: _vm.toAddress
    }
  }, [_vm.token ? [_c("el-popover", {
    attrs: {
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: _vm.latitude && _vm.latitude.address || "Manila"
    }
  }, [_c("div", {
    staticClass: "flex flex-align-center",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/location@2x.png")
    }
  }), _c("span", {
    staticClass: "padding-lr-5 text-ellipsis",
    staticStyle: {
      width: "100px"
    }
  }, [_vm._v(_vm._s(_vm.latitude && _vm.latitude.address || "Manila"))])])])] : _vm._e(), _c("span", {
    staticClass: "padding-lr-5"
  }, [_vm._v("选择地址")]), _c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/down@2x.png")
    }
  })], 2), _c("div", {
    staticClass: "search flex flex-align-center"
  }, [_c("el-input", {
    staticClass: "search-input cursor",
    attrs: {
      placeholder: "搜索商品或商家",
      clearable: ""
    },
    model: {
      value: _vm.searchInput,
      callback: function ($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c("div", {
    staticClass: "search-icon flex flex-center",
    on: {
      click: _vm.search
    }
  }, [_c("img", {
    staticClass: "search-icon-img",
    attrs: {
      src: require("@/assets/icons/search@2x.png")
    }
  })])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };