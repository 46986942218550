var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "address-module-wrap"
  }, [_c("div", {
    staticClass: "flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "flex flex-align-center"
  }, [_c("div", {
    staticClass: "title padding-tb-15 font-s-16 color_3"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.title !== "收货地址" ? _c("div", {
    staticClass: "padding-l-15"
  }, [_c("span", {
    staticClass: "color_75"
  }, [_vm._v("取现时支付跑腿费")]), _c("span", {
    staticClass: "color_primary"
  }, [_vm._v(_vm._s(_vm.deliveryFee) + "₱")])]) : _vm._e()]), _c("div", {
    staticClass: "cursor flex flex-align-center",
    on: {
      click: _vm.openAddressDialog
    }
  }, [_c("img", {
    staticStyle: {
      width: "19px",
      height: "19px"
    },
    attrs: {
      src: require("@/assets/icons/add@2x.png")
    }
  }), _c("div", {
    staticClass: "font-s-14 padding-l-5 color_B0"
  }, [_vm._v("添加新地址")])])]), _c("div", {
    staticClass: "address-list-box"
  }, [_vm._l(_vm.uAddressListShow, function (item, index) {
    return _c("div", {
      key: index
    }, [_vm.uAddressList.length >= 1 ? _c("div", {
      staticClass: "cursor address-list position-re margin-b-10 w-100",
      class: item.checked ? "active" : "no-active",
      on: {
        click: function ($event) {
          return _vm.checkAddress(item);
        }
      }
    }, [_c("div", {
      staticClass: "color_75 font-s-14"
    }, [_c("span", [_vm._v(_vm._s(item.consigner) + " " + _vm._s(item.sex == 2 ? "(女士)" : "(先生)"))]), _c("span", {
      staticClass: "padding-l-10"
    }, [_vm._v(_vm._s(item.mobile))])]), _c("div", {
      staticClass: "color_3 font-s-14 padding-t-10"
    }, [_vm._v(_vm._s(item.address))]), item.checked ? _c("img", {
      staticClass: "position-ab pitch",
      attrs: {
        src: require("@/assets/icons/pitch@2x.png")
      }
    }) : _vm._e(), _vm.isShow && item.need_edit === 1 ? _c("div", {
      staticClass: "position-ab address-tips"
    }, [_c("img", {
      staticClass: "position-re",
      attrs: {
        src: require("@/assets/icons/infrom-bg@2x.png")
      }
    }), _vm._m(0, true)]) : _vm._e()]) : _vm._e()]);
  }), _c("div", {
    staticClass: "cursor",
    on: {
      click: _vm.showMore
    }
  }, [_vm.uAddressListShow.length > 0 ? [_c("span", {
    staticClass: "color_75 font-s-14"
  }, [_vm._v(_vm._s(_vm.isShow ? "显示更多地址" : "收起"))]), _c("i", {
    staticClass: "padding-l-5",
    class: _vm.isShow ? "el-icon-arrow-down" : "el-icon-arrow-up"
  })] : _c("span", {
    staticClass: "color_75 font-s-14"
  }, [_vm._v("您还没新增地址哦~")])], 2)], 2), _vm.dialogAddress ? _c("edit-address-dialog", {
    attrs: {
      params: _vm.params,
      addreddTitle: _vm.addressDialogType === "add" ? "新增" : "编辑",
      isChange: _vm.isChange
    },
    on: {
      sexChange: _vm.sexChange,
      wayChange: _vm.wayChange,
      save: _vm.saveAddress,
      close: function ($event) {
        _vm.dialogAddress = false;
      },
      openDialogAddressList: _vm.openDialogAddressList
    }
  }) : _vm._e(), _vm.dialogAddressList ? _c("address-list-dialog", {
    on: {
      selAddress: _vm.selAddress,
      closeDialog: function ($event) {
        _vm.dialogAddressList = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "position-ab tips-text"
  }, [_c("div", [_vm._v("地址选择功能升级啦，为了提升配送速度，")]), _c("div", {
    staticStyle: {
      "padding-top": "5px"
    }
  }, [_vm._v("快下载筷子生活APP补充完善您当前的地址吧！")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };