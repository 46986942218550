import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import getters from './getters';

// 自动导入模块
const modules = {};
const requireContent = require.context('./modules', true, /\.js$/);
requireContent.keys().forEach(key => {
  const content = requireContent(key);
  const contentName = key.replace(/^\.\//, '').replace(/\.\w+$/, '');
  modules[contentName] = content.__esModule && content.default ? content.default : content;
});
const state = () => ({});
const mutations = {};
const actions = {};
const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
});
export default store;