import addressApi from '../../api/home/address';
export default {
  name: 'AddressIndex',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      doorNoDialog: false,
      showTipDialog: false,
      dialogVisible: false,
      addreddTitle: '新增',
      params: {
        name: '',
        mobile: '',
        sex: '',
        way: '',
        wechatId: '',
        addr: '',
        houseNum: '',
        sexArr: ['女士'],
        sexList: [{
          id: 1,
          name: '先生'
        }, {
          id: 2,
          name: '女士'
        }],
        wayArr: [],
        wayList: [{
          id: 1,
          name: '只能电话联系，APP收不到消息',
          checked: false
        }, {
          id: 2,
          name: '只能APP通知，不需要电话联系',
          checked: false
        }]
      },
      selAddressVal: '',
      // 已选地址
      operationAddress: false,
      // 是否显示可操作修改、删除模块
      dialogAddressList: false,
      // 地址列表弹窗
      addrs: [],
      //用户地址列表
      uAddressList: [],
      // 用户地址列表(展示用)
      selAddressId: '',
      // 选中的地址id
      keyword: '',
      uAddressId: '',
      // 用户地址id
      detailsTips: '',
      // 提示窗内容
      tipsType: '',
      // 提示窗类型
      isMap: false,
      // 打开/隐藏谷歌地图组件
      mapParams: {},
      defaultDoor: false,
      isCut: false
    };
  },
  created() {
    this.getUAddressList();
    if (this.$route.query.isCut) {
      this.isCut = this.$route.query.isCut;
      this.getAddress(false);
    }
  },
  methods: {
    // 关闭提示弹窗
    cancelTisDialog() {
      this.showTipDialog = false;
    },
    // 打开提示弹窗
    openTisDialog(obj, type) {
      this.selAddressId = obj.id;
      if (this.isCut) {
        this.getAddress(true);
      } else {
        this.tipsType = type;
        type === 'del' ? this.detailsTips = '是否刪除地址' : this.detailsTips = '是否确认更改默认地址';
        this.showTipDialog = true;
      }
    },
    // 获取地址信息
    async getAddress(type) {
      try {
        const data = await addressApi.getAddressInfo({
          address_id: this.selAddressId
        });
        this.$store.commit('user/SET_LATITUDE', data);
        if (type) {
          this.$router.go(-1);
        }
      } catch (error) {}
    },
    // 打开系统地址列表弹窗
    openDialogAddressList() {
      this.dialogAddressList = true;
    },
    // 选择性别
    sexChange() {
      if (this.params.sexArr.length > 1) {
        this.params.sexArr.shift();
      }
      this.params.sex = this.params.sexArr[0] === '先生' ? '1' : '2';
    },
    // 选择通知方式
    wayChange() {
      if (this.params.wayArr.length > 1) {
        this.params.wayArr.shift();
      }
      this.params.way = this.params.wayArr[0] === this.params.wayList[0].name ? '1' : '2';
    },
    // 选择地址
    selAddressCheck() {
      if (this.tipsType == 'del') {
        this.delAddress();
      } else {
        this.setAddressDefault();
      }
    },
    // 设置用户默认地址
    async setAddressDefault() {
      try {
        await addressApi.setAddressDefault({
          id: this.selAddressId
        });
        this.$message.success('设置默认地址成功');
        for (let i = 0; i < this.uAddressList.length; i++) {
          const data = this.uAddressList[i];
          if (this.selAddressId === data.id) {
            this.selAddressVal = data.address;
            this.$set(this.uAddressList[i], 'checked', true);
          } else {
            this.$set(this.uAddressList[i], 'checked', false);
          }
        }
        this.$forceUpdate();
        this.showTipDialog = false;
        this.getUAddressList();
      } catch (error) {}
    },
    // 是否显示修改/删除按钮
    management() {
      this.operationAddress = !this.operationAddress;
    },
    // 打开新增/编辑地址弹窗
    openAddressDialog(type, obj) {
      // 清空数据
      this.params.name = '';
      this.params.mobile = '';
      this.params.sex = '';
      this.params.way = '';
      this.params.wechatId = '';
      this.params.addr = '';
      this.selAddressId = '';
      this.params.houseNum = '';
      this.params.wayArr = [];
      this.params.sexArr = [];
      if (type === 'edit') {
        this.mapParams = {};
        this.addreddTitle = '修改';
        // 编辑地址时赋值
        this.params.name = obj.consigner;
        this.params.sex = obj.sex;
        this.params.sexArr[0] = obj.sex === 1 ? this.params.sexList[0].name : this.params.sexList[1].name;
        this.params.mobile = obj.mobile;
        this.params.way = obj.type;
        this.params.wayArr[0] = obj.type === 1 ? this.params.wayList[0].name : this.params.wayList[1].name;
        this.params.addr = obj.address;
        this.selAddressId = obj.address_id;
        this.params.houseNum = obj.door_no;
        this.params.wechatId = obj.wechat;
        this.uAddressId = obj.id;
        this.mapParams.lat = obj.lat;
        this.mapParams.lon = obj.lon;
        // this.uAddressId = obj.is_default
      } else {
        this.addreddTitle = '新增';
        this.params.sexArr = ['女士'];
        this.params.sex = this.params.sexArr[0] === '先生' ? '1' : '2';
        this.uAddressId = '';
      }
      this.dialogVisible = true;
    },
    // 关闭编辑/添加地址弹窗
    closeDialogVisible() {
      this.dialogVisible = false;
    },
    // 选择系统列表地址，赋值到收货地址模块
    selAddress(obj) {
      this.params.addr = obj.address;
      this.dialogAddressList = false;
      this.mapParams = obj;
    },
    // 关闭弹窗事件
    closeDialog() {
      this.dialogAddressList = false;
    },
    // 搜索地址列表
    handleSearch() {
      this.loading = true;
      const kw = this.keyword;
      this.uAddressList = this.addrs.filter(addr => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        return addr['address'].indexOf(kw) >= 0;
      });
    },
    // 获取用户地址列表
    async getUAddressList() {
      try {
        this.addrs = await addressApi.addressIndex();
        for (let i = 0; i < this.addrs.length; i++) {
          const data = this.addrs[i];
          if (!data.checked) {
            data.checked = false;
          }
          if (data.is_default == 1) {
            data.checked = true;
            this.selAddressVal = data.address;
          }
        }
        this.uAddressList = [...this.addrs];
      } catch (error) {}
    },
    // 删除用户地址列表
    async delAddress() {
      try {
        await addressApi.destoryAddress({
          id: this.selAddressId
        });
        this.$message.success("删除地址成功");
        this.showTipDialog = false;
        this.getUAddressList();
      } catch (error) {}
    },
    // 新增/编辑用户地址列表
    async saveAddress() {
      if (!this.params.name) {
        return this.$message.error('请填写收货人姓名');
      }
      if (!this.params.sex) {
        return this.$message.error('请选择性别');
      }
      if (!this.params.mobile) {
        return this.$message.error('请填写当地手机号');
      }
      if (this.params.wayArr.length === 0) {
        return this.$message.error('请选择通知方式');
      }
      if (!this.params.addr) {
        return this.$message.error('请选择收货人位置信息');
      }
      if (this.params.houseNum) {
        this.defaultDoor = true;
      }
      // 无门牌号时，弹出默认大门口Gate弹窗组件
      if (!this.defaultDoor) {
        this.defaultDoor = true;
        return this.doorNoDialog = true;
      }
      const option = {
        consigner: this.params.name,
        sex: this.params.sex,
        mobile: this.params.mobile,
        type: this.params.way,
        // address_id: this.mapParams.lat ? '0' : this.selAddressId,
        door_no: this.params.houseNum,
        wechat: this.params.wechatId,
        lat: this.mapParams.lat,
        lon: this.mapParams.lng || this.mapParams.lon,
        address: this.params.addr,
        id: this.addreddTitle != '添加' ? this.uAddressId : 0
      };
      try {
        const data = await addressApi.storeAddress(option);
        this.dialogVisible = false;
        this.getUAddressList();
        this.$message.success(`${this.addreddTitle}地址成功`);
      } catch (error) {}
    },
    // 打开谷歌地图
    getGppgleAddress() {
      this.isMap = true;
    },
    // 确认选择的地图
    confirmMap(obj) {
      this.mapParams = obj;
      this.isMap = false;
      this.dialogAddressList = false;
      this.params.addr = obj.address;
    },
    // 关闭谷歌地图
    cancelMap() {
      this.isMap = false;
    }
  }
};