/*
 * @Descripttion: home接口文件，使用时import对应的文件
 * @Date: 2022-04-06 14:00:51
 * @LastEditTime: 2022-08-12 14:07:46
 */
import request from '@/common/request';
import $config from '@/config';
export default {
  /**
  * @description 外卖店铺分类
  */
  shopCategory(params) {
    return request({
      url: '/web/shop/category',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 轮播广告
  */
  banner(params) {
    return request({
      url: '/web/common/banner',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 外卖店铺列表
  */
  shopList(params) {
    return request({
      url: '/web/shop/index',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 外卖店铺列表 - 旧
  */
  recommendMerchants(params) {
    return request({
      url: '/api/index/recommendMerchants',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 验证码
  */
  getSmsCode(params) {
    return request({
      url: '/api/Newlogin/getSmsCode',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 商家申请
  */
  shopApply(params) {
    return request({
      url: '/web/merchant/apply',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 外卖搜索
  */
  search(params) {
    return request({
      url: '/web/shop/search',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 账号密码登录
  */
  login(params) {
    return request({
      url: '/web/auth/login',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 用户登录 (短信验证码)
  */
  loginSms(params) {
    return request({
      url: '/web/auth/smslogin',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 注册
  */
  register(params) {
    return request({
      url: '/web/auth/register',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 修改用户密码
  */
  updatePassWord(params) {
    return request({
      url: '/web/auth/forgot',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 用户个人信息
  */
  profile(params) {
    return request({
      url: '/web/user/profile',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 用户账户余额记录
  */
  balance(params) {
    return request({
      url: '/web/user/balance',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 用户收藏店铺
  */
  favourite(params) {
    return request({
      url: '/web/user/favourite',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 用户积分记录
  */
  score(params) {
    return request({
      url: '/web/user/score',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 编辑保存用户信息
  */
  setProfile(params) {
    return request({
      url: '/web/user/setProfile',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  }
};