var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "listBox",
    staticClass: "shop-goods-list-wrap",
    on: {
      scroll: function ($event) {
        return _vm.goodsScroll($event);
      }
    }
  }, _vm._l(_vm.list, function (itemFoods, indexFoods) {
    return _c("div", {
      key: itemFoods.class_id + indexFoods,
      staticClass: "list-box"
    }, [_c("div", {
      staticClass: "padding-b-15 color_75 title",
      attrs: {
        id: "type" + itemFoods.class_id
      }
    }, [_vm._v(_vm._s(itemFoods.name))]), _c("div", {
      staticClass: "goods-card flex flex-wrap"
    }, _vm._l(itemFoods.foods, function (itemGoods, indexGoods) {
      return _c("div", {
        key: indexGoods,
        staticClass: "bg_c_white margin-b-15 flex padding-15 margin-r-15",
        class: {
          "opacity-sty": !itemGoods.isInventory
        },
        staticStyle: {
          width: "calc(50% - 15px)",
          height: "130px"
        },
        attrs: {
          id: "goodsId" + itemGoods.goods_id
        }
      }, [_c("img", {
        staticClass: "bor-r-8",
        staticStyle: {
          width: "100px",
          height: "100px",
          "object-fit": "cover"
        },
        attrs: {
          src: itemGoods.picture
        }
      }), _c("div", {
        staticClass: "goods-info padding-l-10 flex flex-between flex-column"
      }, [_c("div", [_c("div", {
        staticClass: "font-s-18 text-ellipsis"
      }, [_vm._v(_vm._s(itemGoods.goods_name))]), itemGoods.introduction ? _c("el-tooltip", {
        staticClass: "item",
        attrs: {
          effect: "dark",
          content: itemGoods.introduction,
          placement: "top-start"
        }
      }, [_c("div", {
        staticClass: "color_9 padding-t-8 font-s-12 text-ellipsis cursor"
      }, [_vm._v(_vm._s(itemGoods.introduction))])]) : _vm._e(), _c("div", {
        staticClass: "color_9 font-s-12 padding-t-8"
      }, [_vm._v(_vm._s(itemGoods.month_sale_text))])], 1), _c("div", {
        staticClass: "flex flex-between flex-align-end"
      }, [_c("div", [itemGoods.promotion_info ? _c("div", {
        staticClass: "padding-b-5 color_red"
      }, [_vm._v(_vm._s(itemGoods.promotion_info))]) : _vm._e(), _c("div", {
        staticClass: "flex flex-align-end"
      }, [_c("div", {
        staticStyle: {
          color: "#EC4F30"
        }
      }, [_c("span", {
        staticClass: "font-s-12"
      }, [_vm._v("₱")]), _c("span", {
        staticClass: "font-s-16"
      }, [_vm._v(_vm._s(itemGoods.promote_price))])]), itemGoods.price * 1 > itemGoods.promote_price * 1 ? _c("div", {
        staticClass: "font-s-12 color_75 text-decoration padding-l-10"
      }, [_vm._v(" ₱" + _vm._s(itemGoods.price) + " ")]) : _vm._e()])]), _c("div", {
        staticClass: "goods-info-right"
      }, [itemGoods.isInventory ? _c("div", [itemGoods.sku_list.length === 1 && !itemGoods.sku_list[0].sku_name ? [itemGoods.goods_cart_count > 0 ? _c("div", {
        staticClass: "flex-middle num-main"
      }, [_c("div", {
        staticClass: "flex-middle icon-box cursor h-100",
        on: {
          click: function ($event) {
            return _vm.handleDetailsNum("minus", itemGoods);
          }
        }
      }, [_c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/icons/minus@2x.png")
        }
      })]), _c("div", {
        staticClass: "text-center num"
      }, [_vm._v(_vm._s(itemGoods.goods_cart_count))]), _c("div", {
        staticClass: "flex-middle icon-box cursor h-100",
        on: {
          click: function ($event) {
            return _vm.handleDetailsNum("add", itemGoods);
          }
        }
      }, [_c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/icons/addC@2x.png")
        }
      })])]) : _c("div", {
        staticClass: "color_white add-btn cursor",
        on: {
          click: function ($event) {
            return _vm.handleAddCart(itemGoods);
          }
        }
      }, [_vm._v("+")])] : _c("el-badge", {
        staticClass: "specifications-btn",
        attrs: {
          value: itemGoods.goods_cart_count > 0 ? itemGoods.goods_cart_count : ""
        },
        nativeOn: {
          click: function ($event) {
            return _vm.openSpecificationDialog(itemGoods, indexFoods, indexGoods);
          }
        }
      }, [_c("el-button", {
        attrs: {
          size: "small"
        }
      }, [_vm._v("选规格")])], 1)], 2) : _c("el-button", {
        staticClass: "color_white font-s-12 sell-out",
        attrs: {
          size: "small",
          disabled: ""
        }
      }, [_vm._v("已售罄")])], 1)])])]);
    }), 0)]);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };