import "core-js/modules/es.array.push.js";
import homeApi from '../../api/home/index';
export default {
  name: 'NewStoreRecommend',
  data() {
    return {
      shopList: [],
      // 外卖店铺数据
      randomList: [],
      // 随机后的外卖店铺数据
      page: 1
    };
  },
  mounted() {
    this.getShopList();
  },
  methods: {
    changeShop() {
      this.getShopList();
    },
    // 获取外卖店铺列表接口数据
    async getShopList() {
      try {
        const option = {
          page: this.page,
          page_size: '60',
          is_recommend: '1',
          lat: this.$config['LAT'],
          lon: this.$config['LON']
        };
        this.shopObj = await homeApi.recommendMerchants(option);
        this.shopList = this.shopObj.list;

        // 生成随机的数字数组, len为生成的数字长度
        const listLen = this.shopObj.list.length - 1;
        let len = listLen >= 7 ? 7 : listLen;
        let arr;
        const createRandomArr = len => {
          arr = [];
          for (let i = 0; arr.length < len; i++) {
            let num = Number((Math.random(0, 1) * len).toFixed(0));
            if (!arr.includes(num)) {
              arr.push(num);
            }
          }
          return arr;
        };
        createRandomArr(len);
        this.randomList = [];
        // 遍历生成的随机下标数组，重新生成新的列表渲染到页面
        arr.forEach(i => {
          this.randomList.push(this.shopList[i]);
        });
      } catch (error) {}
    },
    // 打开店铺详情页面
    hanldeshopDetals(id) {
      this.$router.push({
        path: "/waimai/shopDetails",
        query: {
          shopId: id
        }
      });
    }
  }
};