var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "order-list-wrap"
  }, [_vm.list.length > 0 ? _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "list margin-b-15",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.toOrderDetails(item.id);
        }
      }
    }, [_c("div", {
      staticClass: "list-top"
    }, [_c("div", {
      staticClass: "flex flex-between"
    }, [_c("div", {
      staticClass: "font-s-16",
      style: "color:" + item.status_color
    }, [_vm._v(_vm._s(item.status_text))])]), _c("div", {
      staticClass: "flex flex-between margin-t-5 color_75 font-s-12"
    }, [_c("div", {
      staticClass: "flex"
    }, [_c("div", [_vm._v(" " + _vm._s(_vm.$moment(item.pre_shipping_time[0] * 1000).format("YYYY年MM月DD日 HH:mm")) + " - " + _vm._s(_vm.$moment(item.pre_shipping_time[1] * 1000).format("HH:mm")) + " ")]), _c("div", {
      staticClass: "line"
    }), _c("div", [_vm._v(_vm._s(item.is_cod == 0 ? "" : "货到付款"))]), item.is_cod != 0 ? _c("div", {
      staticClass: "line"
    }) : _vm._e(), _c("div", [_vm._v(_vm._s(item.shipping_type == 1 ? "平台配送" : "商家配送"))]), _c("div", {
      staticClass: "line"
    }), _c("div", [_vm._v("订单号：" + _vm._s(item.no))]), item.package_code ? _c("div", {
      staticClass: "line"
    }) : _vm._e(), item.package_code ? _c("div", {
      staticClass: "color_primary"
    }, [_vm._v("取餐码：" + _vm._s(item.package_code))]) : _vm._e()]), _c("div", {
      staticStyle: {
        "margin-right": "28px"
      }
    }, [_c("span", [_vm._v("支付金额：")]), _c("span", {
      staticStyle: {
        "font-size": "22px",
        "margin-right": "5px"
      }
    }, [_vm._v(_vm._s(item.amount))]), _c("span", [_vm._v("₱")])])])]), _c("div", {
      staticClass: "flex flex-between",
      staticStyle: {
        padding: "20px 28px"
      }
    }, [_c("div", {
      staticClass: "flex flex-align-center"
    }, [_c("img", {
      staticClass: "bor-r-4",
      staticStyle: {
        width: "96px",
        height: "96px",
        "margin-right": "16px",
        "object-fit": "cover"
      },
      attrs: {
        src: item.shop.image
      }
    }), _c("div", [_c("div", {
      staticClass: "font-s-16"
    }, [_vm._v(_vm._s(item.shop.title))]), _c("div", {
      staticClass: "color_75 font-s-12 text-ellipsis",
      staticStyle: {
        width: "440px",
        "margin-top": "8px"
      }
    }, [_vm._l(item.details, function (detailItem, detailIndex) {
      return [_c("span", {
        key: detailItem.id
      }, [_vm._v(_vm._s(detailItem.goods_title) + "(" + _vm._s(detailItem.num) + ")")]), detailIndex !== item.details.length - 1 ? _c("span", {
        key: detailItem.id
      }, [_vm._v(" / ")]) : _vm._e()];
    })], 2)])]), _c("div", {
      staticClass: "btn-box"
    }, [_vm._l(item.operations, function (btnItem, btnIndex) {
      return _c("div", {
        key: btnIndex,
        staticClass: "btn-list cursor font-s-12",
        class: {
          "margin-b-10": btnIndex !== item.operations.length - 1
        },
        on: {
          click: function ($event) {
            $event.stopPropagation();
            return _vm.handleBtn(btnItem.op_id, item.id, item.shop.id, item.amount, item.out_trade_no);
          }
        }
      }, [_vm._v(_vm._s(btnItem.name))]);
    }), _c("div", {
      staticClass: "btn-list cursor font-s-12 margin-t-10",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.handleMerchant(item.id, item.shop.id);
        }
      }
    }, [_vm._v("联系商家")])], 2)])]);
  }) : _vm._e(), _vm.sourceModule !== "home" ? _c("bottom-tip", {
    attrs: {
      text: "当前没有交易订单。",
      list: _vm.list,
      page: _vm.page,
      pageTotal: _vm.pageTotal
    }
  }) : _vm._e(), _vm.showTipDialog ? _c("tooltip-dialog", {
    attrs: {
      title: "温馨提示",
      details: "是否取消订单？"
    },
    on: {
      cancel: function ($event) {
        _vm.showTipDialog = false;
      },
      confirm: _vm.cancelOrder,
      closeDialog: function ($event) {
        _vm.showTipDialog = false;
      }
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };