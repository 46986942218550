/*
 * @Description: 模块化配置
 * @Author: By AC
 * @Date: 2020-12-20 17:01:43
 * @Email: 289065365@qq.com
 * @LastEditTime: 2020-12-31 11:17:44
 * @LastEditors: // code
 */
/**
 * @description 混入模块配置
 * 可配置vue或小程序生命周期相关的混入如: computed methods filters
 * 模块方法：如要添加methods 方法 则在minxin目录下建立 methods.js然后定义方法即可
 */

const minxin = {};
const requireContent = require.context('.', true, /\.js$/);
requireContent.keys().forEach(key => {
  const content = requireContent(key);
  const contentName = key.replace(/^\.\//, '').replace(/\.\w+$/, '');
  if (!contentName.match(/^i\w+ex$/g)) minxin[contentName] = content.__esModule && content.default ? content.default : content;
});
console.log(minxin);
export default minxin;