import "core-js/modules/es.array.push.js";
import orderApi from '../../api/waimai/order';
export default {
  name: 'OnlinePay',
  props: {
    list: {
      type: Array,
      default: () => []
    }
    // outTradeNo: {
    //   type: String,
    //   default: ''
    // }
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibleWX: false,
      // payObj: {},
      payTypeData: {},
      payTypeActive: 0,
      paymentTypeVal: '',
      minutes: 0,
      // 倒计时分
      seconds: 0,
      // 倒计时秒
      wxData: {},
      isShowBtn: false
    };
  },
  watch: {
    second: {
      handler(newVal) {
        this.num(newVal);
      }
    },
    minute: {
      handler(newVal) {
        this.num(newVal);
      }
    }
  },
  computed: {
    second() {
      return this.num(this.seconds);
    },
    minute() {
      return this.num(this.minutes);
    }
  },
  mounted() {
    if (this.$route.query.outTradeNo) {
      this.outTradeNo = this.$route.query.outTradeNo;
      this.getPayInfoNew();
    }
    this.$bus.$off('openIsPayDialog');
    this.$bus.$on('openIsPayDialog', () => {
      this.dialogVisible = true;
    });
    this.$bus.$off('openWXPayDialog');
    this.$bus.$on('openWXPayDialog', data => {
      this.handleDialogVisibleWX(data);
    });
  },
  methods: {
    selPayType(obj, index) {
      // this.payObj = obj
      this.payTypeActive = index;
      this.$emit('selPayType', obj);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleDialogVisibleWX(wxData) {
      this.wxData = wxData;
      this.dialogVisibleWX = !this.dialogVisibleWX;
    },
    accomplishPay() {
      this.queryOrderAndUpdate();
    },
    // 取消支付
    cancelPay() {
      this.$store.commit('user/SET_ISONLINEPAY', false);
      this.$router.push({
        path: '/user/index',
        query: {
          currentCollapse: 'takeout'
        }
      });
    },
    openIsPayDialog() {
      this.dialogVisible = true;
    },
    // 确认支付
    affirmPay() {
      this.$emit('affirmPay');
    },
    // 获取支付信息数据
    async queryOrderAndUpdate() {
      try {
        const option = {
          out_trade_no: this.outTradeNo
        };
        const data = await orderApi.queryOrderAndUpdate(option);
        if (data.is_pay == 0) {
          return this.$message.error('未查询到已完成支付');
        } else {
          this.dialogVisible = false;
          this.dialogVisibleWX = false;
          this.$store.commit('user/SET_ISONLINEPAY', false);
          setTimeout(() => {
            this.$router.push({
              path: "/user/index",
              query: {
                currentCollapse: 'takeout'
              }
            });
          }, 1000);
        }
      } catch (error) {}
    },
    // 获取支付信息数据
    async getPayInfoNew() {
      try {
        const option = {
          out_trade_no: this.outTradeNo,
          ftype: 'web'
        };
        this.payTypeData = await orderApi.getPayInfoNew(option);
        this.isShowBtn = true;
        for (let index = 0; index < this.payTypeData.pay_config.length; index++) {
          var payItem = this.payTypeData.pay_config[index];
          if (payItem.payment_type == 'fu_ali') {
            this.paymentTypeVal = 21;
          } else if (payItem.payment_type == 'balance') {
            this.paymentTypeVal = 3;
          } else if (payItem.payment_type == 'fu_wx') {
            this.paymentTypeVal = 20;
          } else if (payItem.payment_type == 'fu_grab') {
            this.paymentTypeVal = 19;
          } else if (payItem.payment_type == 'fu_gcash') {
            this.paymentTypeVal = 18;
          }
        }
        // this.paymentTypeVal = (this.payTypeData.pay_config[0].payment_type == 'fu_ali' || this.payTypeData.pay_config[0].payment_type == 'ali') ? 21 : 3
        this.$emit('selPayType', this.payTypeData.pay_config[0]);
        this.balance = this.payTypeData.balance;
        var remainSeconds = parseInt(this.payTypeData.remain_seconds * 1);
        if (remainSeconds > 0) {
          this.minutes = Math.round(remainSeconds / 60);
          this.countTime();
        }
      } catch (error) {
        // setTimeout(() => {
        //   this.$router.push({
        //     path:"/user/index",
        //     query: { currentCollapse: 'takeout' }
        //   })
        // }, 1000)
      }
    },
    num(n) {
      return n < 10 ? '0' + n : '' + n;
    },
    // 倒计时
    countTime() {
      var timeIndex = setInterval(() => {
        if (this.seconds === 0 && this.minutes !== 0) {
          this.seconds = 59;
          this.minutes -= 1;
        } else if (this.minutes === 0 && this.seconds === 0) {
          this.$store.commit('user/SET_ISONLINEPAY', false);
          this.seconds = 0;
          clearInterval(timeIndex);
          this.$router.push({
            path: "/user/index",
            query: {
              currentCollapse: 'takeout'
            }
          });
        } else {
          this.seconds -= 1;
        }
      }, 1000);
    }
  }
};