import "core-js/modules/es.array.push.js";
import orderApi from '../../api/waimai/order';
export default {
  name: 'OrderList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    page: {
      type: Number,
      default: 1
    },
    pageTotal: {
      type: Number,
      default: 1
    },
    sourceModule: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      payPasswordDialog: false,
      totalMoney: 0,
      // 商品总金额
      outTradeNo: '',
      option: {},
      showTipDialog: false,
      cancelOrderId: '',
      // 取消订单id
      payObj: {},
      paymentTypeVal: ''
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    profile() {
      return this.$store.state.user.profile;
    }
  },
  mounted() {
    // 先解绑，不然会多次触发
    this.$bus.$off('affirmPayFun');
    // 确认支付
    this.$bus.$on('affirmPayFun', (obj, type, option) => {
      this.payObj = obj;
      this.paymentTypeVal = type;
      this.confirmPay(option);
    });
  },
  methods: {
    delDialog(id) {
      this.$emit('openDailog', id);
    },
    // 跳转到订单详情
    toOrderDetails(id) {
      this.$emit('toOrderDetails', id);
    },
    // 联系商家
    handleMerchant(orderId, merchantId) {
      if (this.token) {
        var web_im_url = this.profile.im_info.web_im_url;
        if (web_im_url) {
          if (this.profile.uid) {
            const url = web_im_url + '&order_id=' + orderId + '&merchant_id=' + merchantId + '&entrance=2' + '&contact_type=merchant' + '&msg=' + encodeURI('您好！');
            window.open(url);
          } else {
            this.$bus.$emit('openDialog', 'login');
          }
        } else {
          this.$bus.$emit('getPublicParams');
        }
      }
    },
    // 按钮: 1=再来一单,2=取消订单,3=立即支付,5=评价得3积分,6=修改订单,7=打赏,8=完成打赏支付,9=完成差价支付,10=申请售后,12=查看评价,13=删除
    handleBtn(btnId, orderId, shopId, amount, no) {
      switch (btnId) {
        case 1:
          this.oneMoreOrder(orderId, shopId);
          break;
        case 2:
          this.cancelOrderId = orderId;
          this.showTipDialog = true;
          break;
        case 3:
          this.outTradeNo = no;
          this.totalMoney = amount;
          this.$store.commit('user/SET_ISONLINEPAY', true);
          this.$router.push({
            path: this.$route.path,
            query: {
              currentCollapse: this.$route.query.currentCollapse,
              isOnlinePay: true,
              outTradeNo: this.outTradeNo
            }
          });
          // this.payPasswordDialog = true
          break;
        // case 5:
        //   break;
        // case 10:
        //   break;
        // case 12:
        // break;
        case 13:
          this.delDialog(orderId);
          break;
        default:
          this.$message('功能开发中');
          break;
      }
    },
    // 取消订单
    async cancelOrder() {
      try {
        await orderApi.cancelOrder({
          order_id: this.cancelOrderId
        });
        this.$message.success('取消订单成功');
        this.showTipDialog = false;
        setTimeout(() => {
          this.$emit('refresh');
        }, 1000);
      } catch (error) {}
    },
    // 再来一单
    async oneMoreOrder(orderId, shopId) {
      try {
        await orderApi.oneMoreOrder({
          order_id: orderId
        });
        this.$router.push({
          path: "/waimai/orderSave",
          query: {
            merchantId: shopId
          }
        });
      } catch (error) {}
    },
    confirmPay(option) {
      if (this.payObj.payment_type == 'balance') {
        this.option = option;
        this.getBalance();
      } else {
        this.payOrders();
      }
    },
    // 获取用户余额数据
    async getBalance() {
      try {
        const data = await orderApi.getBalance({
          uid: this.$store.state.user.profile.uid
        });
        this.balance = data.balance;
        // 余额不够
        if (data.balance * 1 < this.totalMoney * 1) {
          this.$message.error('您的余额不够，请先联系平台客服充值，或者找兑换的商家兑换余额');
          setTimeout(() => {
            this.$router.push({
              path: "/user/index",
              query: {
                currentCollapse: 'recharge'
              }
            });
          }, 1000);
          return;
        }
        // 存在支付密码
        if (data.checkPayPassword == 1) {
          this.verifyPayPassword();
        }
      } catch (error) {}
    },
    // 校验支付密码
    async verifyPayPassword() {
      try {
        const data = await orderApi.verifyPayPassword({
          payPassword: this.option.password
        });
        if (data.verifyPayPassword == 1) {
          this.payOrders();
        } else {
          this.$message.error('密码错误，请重新输入');
        }
      } catch (error) {}
    },
    // 订单支付
    async payOrders() {
      try {
        const option = {
          out_trade_no: this.outTradeNo,
          payment_type: this.paymentTypeVal,
          payment_hash: this.payObj.payment_hash,
          ftype: 'web'
        };
        const data = await orderApi.payOrders(option);
        if (data.pay_type == 21) {
          if (data.alipay_params.pay_url) {
            window.open(data.alipay_params.pay_url);
            this.$bus.$emit('openIsPayDialog');
            // window.location.href = data.alipay_params.pay_url
          } else {
            this.$message.error(data.alipay_params);
            this.toTakeOutPage();
          }
        } else if (data.pay_type == 20) {
          this.$bus.$emit('openWXPayDialog', data.wxpay_params);
        } else if (data.pay_type == 19 || data.pay_type == 18) {
          if (data.wxpay_params.api_url) {
            window.open(data.wxpay_params.api_url);
          } else {
            this.$message.error(data.wxpay_params.error_message);
          }
        } else {
          this.$bus.$emit('closeDialog');
          this.$message.success('支付成功');
          this.toTakeOutPage();
        }
      } catch (error) {
        this.toTakeOutPage();
      }
    },
    // 跳转到订单列表页面
    toTakeOutPage() {
      this.$store.commit('user/SET_ISONLINEPAY', false);
      setTimeout(() => {
        this.$router.push({
          path: "/user/index",
          query: {
            currentCollapse: 'takeout'
          }
        });
      }, 1000);
    }
  }
};