export default {
  name: 'LeftProfile',
  props: {
    personalCenter: {
      type: Array,
      default: () => []
    },
    currentCollapse: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {};
  },
  methods: {
    selCollapse(id) {
      this.$emit('selCollapse', id);
    },
    handleCollapse(obj) {
      this.$emit('handleCollapse', obj);
    }
  }
};