/*
 * @Descripttion: 披索充值接口文件，使用时import对应的文件
 * @Date: 2022-07-12 16:21:45
 * @LastEditTime: 2022-08-06 15:03:54
 */
import request from '@/common/request';
import $config from '@/config';
export default {
  /**
  * @description 获取披索充值信息
  */
  balanceRecharge(params) {
    return request({
      url: '/web/recharge/balanceRecharge',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 创建充值订单
  */
  doRecharge(params) {
    return request({
      url: '/api/Recharge/doRecharge',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  }
};