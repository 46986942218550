import Vue from 'vue';
import App from './App';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import '@/styles/index.scss'; // global css

import store from './store';
import router from './router';
import '@/common/filter'; // 引入过滤器
import '@/common/directive'; // 引入指令

import '@/components/index.js'; // 引入全局组件

Vue.config.productionTip = false;

// 全局变量
import $config from './config';
Vue.prototype.$config = $config;

// minxin
import minxin from '@/common/minxin';
Vue.mixin(minxin);
import moment from 'moment';
Vue.prototype.$moment = moment;
Vue.prototype.$bus = new Vue(); // event Bus 用于无关系组件间的通信。

// api
// import $api from './api/demo'
// Vue.prototype.$api = $api

Vue.use(ElementUI);
window.$app = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});