var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "address-list-wrap bg_c_white"
  }, [_c("div", {
    staticClass: "top flex flex-align-center flex-between padding-b-10"
  }, [_c("div", {
    staticClass: "title color_75"
  }, [_vm._v("地址管理")]), _c("div", {
    staticClass: "search flex flex-align-center"
  }, [_c("el-input", {
    staticClass: "search-input cursor",
    attrs: {
      placeholder: "搜索您的收货地址",
      clearable: ""
    },
    model: {
      value: _vm.keyword,
      callback: function ($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }), _c("div", {
    staticClass: "search-icon flex flex-center",
    on: {
      click: _vm.handleSearch
    }
  }, [_c("img", {
    staticClass: "search-icon-img",
    attrs: {
      src: require("@/assets/icons/search@2x.png")
    }
  })])], 1)]), _c("div", {
    staticClass: "flex font-s-16 sel-address"
  }, [_c("div", {
    staticClass: "color_primary"
  }, [_vm._v("已选地址")]), _c("div", {
    staticClass: "color_75"
  }, [_vm._v(_vm._s(_vm.selAddressVal))])]), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "padding-t-10 address-list-box"
  }, [_c("div", {
    staticClass: "padding-tb-15 flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "font-s-16"
  }, [_vm._v("我的收货地址")]), _c("div", {
    staticClass: "font-s-14 manage cursor",
    class: _vm.operationAddress ? "manage-active" : "manage-gray color_75",
    on: {
      click: _vm.management
    }
  }, [_vm._v(_vm._s(_vm.operationAddress ? "取消" : "管理"))])]), _vm._l(_vm.uAddressList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "address-list flex flex-align-center flex-between cursor position-re",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.openTisDialog(item);
        }
      }
    }, [item.is_default == 1 ? _c("div", {
      staticClass: "bg_c_main color_white font-s-12 padding-5 position-ab",
      staticStyle: {
        top: "0",
        left: "0"
      }
    }, [_vm._v("默认")]) : _vm._e(), _c("div", {
      staticStyle: {
        width: "85%"
      }
    }, [_c("div", {
      staticClass: "font-s-16"
    }, [_vm._v(_vm._s(item.address))]), _c("div", {
      staticClass: "padding-t-10 font-s-14 color_75"
    }, [_c("span", [_vm._v(_vm._s(item.consigner))]), _c("span", {
      staticClass: "padding-l-10"
    }, [_vm._v(_vm._s(item.mobile))])])]), [_vm.operationAddress ? _c("div", {
      staticClass: "flex"
    }, [_c("div", {
      staticClass: "operation-btn font-s-12 padding-5",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.openAddressDialog("edit", item);
        }
      }
    }, [_vm._v("修改")]), _c("div", {
      staticClass: "operation-btn margin-l-15 font-s-12 padding-5",
      on: {
        click: function ($event) {
          $event.stopPropagation();
          return _vm.openTisDialog(item, "del");
        }
      }
    }, [_vm._v("删除")])]) : _c("el-checkbox", {
      staticClass: "color_75",
      model: {
        value: item.checked,
        callback: function ($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    })]], 2);
  }), _c("div", {
    staticClass: "address-list-add flex-middle cursor",
    on: {
      click: _vm.openAddressDialog
    }
  }, [_c("img", {
    staticStyle: {
      width: "24px",
      height: "24px"
    },
    attrs: {
      src: require("@/assets/icons/addsite@2x.png")
    }
  }), _c("div", {
    staticClass: "font-s-14 padding-l-5 color_B0"
  }, [_vm._v("添加新地址")])])], 2), _vm.dialogVisible ? _c("edit-address-dialog", {
    attrs: {
      params: _vm.params,
      addreddTitle: _vm.addreddTitle
    },
    on: {
      sexChange: _vm.sexChange,
      wayChange: _vm.wayChange,
      save: _vm.saveAddress,
      close: _vm.closeDialogVisible,
      openDialogAddressList: _vm.openDialogAddressList
    }
  }) : _vm._e(), _vm.dialogAddressList ? _c("address-list-dialog", {
    attrs: {
      address: _vm.params.addr
    },
    on: {
      selAddress: _vm.selAddress,
      closeDialog: _vm.closeDialog,
      getGppgleAddress: _vm.getGppgleAddress
    }
  }) : _vm._e(), _vm.showTipDialog ? _c("tooltip-dialog", {
    attrs: {
      title: "温馨提示",
      details: _vm.detailsTips
    },
    on: {
      cancel: _vm.cancelTisDialog,
      confirm: _vm.selAddressCheck,
      closeDialog: _vm.cancelTisDialog
    }
  }) : _vm._e(), _c("el-dialog", {
    attrs: {
      title: "温馨提示",
      visible: _vm.doorNoDialog,
      width: "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.doorNoDialog = $event;
      },
      close: function ($event) {
        _vm.doorNoDialog = false;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("div", [_vm._v("您没有填写门牌号")]), _c("div", {
    staticClass: "padding-t-5"
  }, [_vm._v("将默认为大门口Gate")])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "btn-cancel",
    on: {
      click: function ($event) {
        _vm.doorNoDialog = false;
      }
    }
  }, [_vm._v("返回修改")]), _c("el-button", {
    staticClass: "btn-confirm",
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.doorNoDialog = false;
      }
    }
  }, [_vm._v("确认保存")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };