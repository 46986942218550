import TitlePanel from '@/components/serve/title-panel.vue';
import DetailList from '@/components/serve/detail-list.vue';
export default {
  name: 'Integral',
  components: {
    TitlePanel,
    DetailList
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {}
};