var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.showBtn ? _c("div", {
    staticClass: "backtop-wrap position-fi bg_c_white flex-middle flex-column cursor",
    style: `bottom: ${_vm.bottom};`,
    on: {
      click: _vm.handleClick,
      mouseover: _vm.handleHover,
      mouseout: _vm.handleOut
    }
  }, [_vm.name === "购物车" ? _c("el-badge", {
    staticClass: "item",
    attrs: {
      value: _vm.value > 0 ? _vm.value : ""
    }
  }, [_c("img", {
    staticClass: "img",
    attrs: {
      src: require(`@/assets/icons/${_vm.icon}.png`)
    }
  })]) : _vm.name === "客服中心" ? _c("el-badge", {
    staticClass: "item",
    attrs: {
      value: _vm.noticeCounts > 0 ? _vm.noticeCounts : ""
    }
  }, [_c("img", {
    staticClass: "img",
    attrs: {
      src: require(`@/assets/icons/${_vm.icon}.png`)
    }
  })]) : _c("img", {
    staticClass: "img",
    attrs: {
      src: require(`@/assets/icons/${_vm.icon}.png`)
    }
  }), _c("div", {
    staticClass: "name font-s-12"
  }, [_vm._v(_vm._s(_vm.name))])], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };