var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tooltip-dialog-wrap"
  }, [_c("el-dialog", {
    attrs: {
      title: _vm.title,
      visible: _vm.dialogVisible,
      width: "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.closeDialog
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.details))]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.showCancel ? _c("el-button", {
    staticClass: "btn-cancel",
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]) : _vm._e(), _c("el-button", {
    staticClass: "btn-confirm",
    attrs: {
      type: "primary",
      disabled: _vm.disabledBtn
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v(_vm._s(_vm.loadingBtnText))])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };