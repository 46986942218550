var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mapdiv"
  }, [_c("div", {
    ref: "allmap",
    style: {
      height: _vm.h,
      width: "100%"
    },
    attrs: {
      id: "allmap"
    }
  }), _c("div", {
    staticClass: "addList"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.keyword,
      expression: "keyword"
    }],
    ref: "keywordText",
    attrs: {
      type: "text"
    },
    domProps: {
      value: _vm.keyword
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.keyword = $event.target.value;
      }
    }
  })]), _c("div", {
    staticClass: "bottom-tool"
  }, [_vm._m(0), _c("div", {
    staticStyle: {
      "margin-left": "15px"
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.location))]), _c("p", {
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_vm._v(_vm._s(_vm.city))])])]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center",
      "padding-bottom": "15px"
    }
  }, [_c("el-button", {
    staticClass: "save-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v("保存")])], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("img", {
    attrs: {
      src: require("../../assets/icons/location@2x.png")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };