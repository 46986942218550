var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bottom-tip-wrap"
  }, [_vm.list && _vm.list.length > 0 ? [_vm.page < _vm.pageTotal ? _c("div", {
    staticClass: "loadTips flex-middle"
  }, [_c("img", {
    staticClass: "load",
    attrs: {
      src: require("@/assets/icons/load@2x.png")
    }
  }), _c("div", [_vm._v("正在加载...")])]) : _c("div", {
    staticClass: "loadTips flex-middle"
  }, [_vm._v("没有更多了")])] : _c("div", {
    staticClass: "loadTips flex-middle"
  }, [_vm._v(_vm._s(_vm.text))])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };