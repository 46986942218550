var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "recommend-list-wrap"
  }, [_vm.shopList.length > 0 ? _c("div", [_c("div", {
    staticClass: "flex flex-wrap"
  }, _vm._l(_vm.shopList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "list flex margin-b-10 bg_c_white padding-15 cursor margin-r-15",
      on: {
        click: function ($event) {
          return _vm.handleCard(item);
        }
      }
    }, [_c("img", {
      staticClass: "margin-r-10",
      staticStyle: {
        "object-fit": "cover"
      },
      attrs: {
        src: !item.shop ? item.image : item.shop.image
      }
    }), _c("div", {
      staticClass: "flex flex-aligin-center flex-around flex-column"
    }, [_c("div", {
      staticClass: "title font-s-16 text-ellipsis margin-t-5"
    }, [_vm._v(_vm._s(!item.shop ? item.title : item.shop.title))]), _c("div", {
      staticClass: "middle flex flex-align-center font-s-12"
    }, [_c("img", {
      staticClass: "star",
      attrs: {
        src: require("@/assets/icons/star@2x.png")
      }
    }), _c("span", {
      staticClass: "color_main"
    }, [_vm._v(_vm._s(!item.shop ? item.stars : item.shop.stars) + "分")]), _c("span", {
      staticClass: "color_B0 margin-l-10"
    }, [_vm._v("月售" + _vm._s(!item.shop ? item.month_sales : item.shop.month_sales))])]), _c("div", [_vm._v(_vm._s(!item.shop ? item.min_consume : item.shop.min_consume) + "起送")])])]);
  }), 0)]) : _vm._e(), _vm.sourceModule !== "home" ? _c("bottom-tip", {
    attrs: {
      text: _vm.source === "collect" ? "您暂未收藏任何商家。" : "暂无数据",
      list: _vm.shopList,
      page: _vm.page,
      pageTotal: _vm.pageTotal
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };