import "core-js/modules/es.array.push.js";
export default {
  name: 'ShopRightWrap',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      scrollY: 0 // 滑动的y轴坐标
    };
  },
  methods: {
    handleAddCart(itemGoods) {
      this.$emit('handleAddCart', itemGoods);
    },
    handleDetailsNum(type, obj) {
      this.$emit('handleDetailsNum', type, obj);
    },
    openSpecificationDialog(itemGoods, indexFoods, indexGoods) {
      this.$emit('openSpecificationDialog', itemGoods, indexFoods, indexGoods);
    },
    goodsScroll(e) {
      this.scrollY = e.target.scrollTop;
      this.initRightBoxHeight();
    },
    // 计算每个小盒子高度
    initRightBoxHeight(scrollY) {
      let itemArray = [];
      let top = 0;
      itemArray.push(top);
      // 获取右边所有子盒子高度集合
      let allList = this.$refs.listBox.getElementsByClassName('list-box');
      // allList伪数组转化成真数组
      Array.prototype.slice.call(allList).forEach(li => {
        top += li.clientHeight; //获取所有li的每一个高度
        itemArray.push(top);
      });
      this.rightLiTops = itemArray;
      this.rightLiTops.forEach((item, index) => {
        if (item <= this.scrollY) {
          this.$emit('activeTypeScroll', index);
        }
      });
    }
  }
};