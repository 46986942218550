import "core-js/modules/es.array.push.js";
export default {
  name: 'Carousel',
  props: {
    bannerList: {
      type: Array,
      default: () => []
    },
    menuList: {
      type: Array,
      default: () => []
    },
    source: {
      type: String,
      default: 'home'
    }
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    profile() {
      return this.$store.state.user.profile;
    }
  },
  methods: {
    // 经过/离开菜单事件
    handleMenu(menuItem) {
      menuItem.show_menu = !menuItem.show_menu;
    },
    // 打开登录/注册弹窗
    handleDialog(type) {
      this.$bus.$emit('openDialog', type);
    },
    // 退出登录
    logout() {
      this.$bus.$emit('handleLogout');
    },
    skip(obj) {
      this.$emit('skip', obj);
    },
    // 点击轮播图方法
    handleCarousel(obj) {
      // 跳转类型：5=外卖商家，6=外部链接，7=不跳转，11-2=外卖页，11-7=余额页面，13-2=外卖关键词搜索结果页
      if (obj.type_id == '5') {
        this.$router.push({
          path: "/waimai/shopDetails",
          query: {
            shopId: obj.type_content
          }
        });
      } else if (obj.type_id == '6') {
        window.open(obj.type_content);
      } else if (obj.type_id == '7') {
        return;
      } else if (obj.type_id == '11-2') {
        this.$router.push({
          path: "/waimai/index"
        });
      } else if (obj.type_id == '11-7') {
        this.$router.push({
          path: "/user/index",
          query: {
            currentCollapse: 'balance',
            type: 'recharge'
          }
        });
      } else if (obj.type_id == '13-2') {
        this.$router.push({
          path: '/search/result',
          query: {
            content: obj.type_content
          }
        });
      }
    }
  }
};