var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.randomList.length > 0 ? _c("div", {
    staticClass: "shop-recommend"
  }, [_c("div", {
    staticClass: "shop-recommend-title margin-t-15 flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "font-s-18"
  }, [_vm._v("新店推荐")]), _c("div", {
    staticClass: "flex flex-align-center change cursor",
    on: {
      click: _vm.changeShop
    }
  }, [_c("div", [_vm._v("换一换")]), _c("img", {
    staticClass: "change-icon change-grey margin-l-5",
    attrs: {
      src: require("@/assets/icons/change@2x.png")
    }
  }), _c("img", {
    staticClass: "change-icon change-hover margin-l-5",
    attrs: {
      src: require("@/assets/icons/change2@2x.png")
    }
  })])]), _c("div", {
    staticClass: "bg_c_white"
  }, [_c("div", {
    staticClass: "flex shop-list"
  }, _vm._l(_vm.randomList, function (item, index) {
    return _c("img", {
      key: index,
      staticClass: "cursor margin-r-15",
      staticStyle: {
        "object-fit": "cover"
      },
      attrs: {
        src: item && item.image
      },
      on: {
        click: function ($event) {
          return _vm.hanldeshopDetals(item.id);
        }
      }
    });
  }), 0)])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };