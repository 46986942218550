import "core-js/modules/es.array.push.js";
import orderApi from '../../api/waimai/order';
export default {
  name: 'deliveryTime',
  props: {
    merchantId: {
      type: String,
      default: ''
    },
    activeTime: {
      type: Array,
      default: () => []
    },
    addressId: {
      type: [Number, String],
      default: 0 || ''
    },
    source: {
      type: String,
      default: 'order'
    }
  },
  data() {
    return {
      deliveryTime: [],
      timeList: [],
      deliveryTimeList: [],
      orderPrice: '',
      goodsIds: ''
    };
  },
  watch: {
    deliveryTimeList: {
      handler(val) {
        if (val && val.length > 0) {
          this.timeList = this.resolveData(val);
          // this.onChooseDay(0);
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.source !== 'order') {
      this.getDeliveryTime();
    }
  },
  methods: {
    init(ids, val) {
      if (ids && val) {
        this.goodsIds = ids;
        this.orderPrice = val;
        this.getDeliveryTime();
      }
    },
    // 处理数据
    resolveData(list) {
      let target = []; // 目的数组
      let keys = [];
      list.forEach(item => {
        if (!keys.includes(item.type)) {
          target.push({
            type: item.type,
            date: item.type_name_display,
            value: item.type_name_display,
            label: item.type_name_display,
            children: [{
              shipping_time_display: item.shipping_time_display,
              send_time: item.shipping_time,
              is_asap: item.asap,
              value: item.shipping_time_display,
              label: item.fastigium_str ? item.shipping_time_display + ' (高峰期)' : item.shipping_time_display,
              type_name: item.type_name
            }],
            send_time: item.shipping_time,
            is_asap: item.asap
          });
          keys.push(item.type);
        } else {
          const current = target.filter(targetItem => {
            return item.type === targetItem.type;
          })[0];
          current.children.push({
            shipping_time_display: item.shipping_time_display,
            send_time: item.shipping_time,
            is_asap: item.asap,
            value: item.shipping_time_display,
            label: item.fastigium_str ? item.shipping_time_display + ' (高峰期)' : item.shipping_time_display,
            type_name: item.type_name
          });
        }
      });

      // 存在时间数据时，第一项作为默认焦点
      if (this.activeTime.length > 0) {
        var arr = [target[0].value, target[0].children[0].value];
        this.deliveryTime = arr;
      }
      return target;
    },
    handleCascader(arr) {
      this.deliveryTime = arr;
      let getCheckedNodes = this.$refs.cascader.getCheckedNodes()[0];
      let data = getCheckedNodes.data;
      let list = [];
      list[0] = data.type_name;
      list[1] = data.send_time;
      this.$emit('handleCascader', list);

      // this.deliveryTime = arr
      // if (this.activeTime.length > 0) {
      //   let getCheckedNodes = this.$refs.cascader.getCheckedNodes()[0]
      //   let data = getCheckedNodes.data
      //   let list = []
      //   list[0] = data.type_name
      //   list[1] = data.send_time
      //   this.$emit('handleCascader', list)
      // } else {
      //   this.$emit('handleCascader', arr)
      // }
    },
    async getDeliveryTime() {
      try {
        const option = {
          merchant_id: this.merchantId,
          address_id: this.addressId,
          // order_price: this.orderPrice,
          goods_ids: this.goodsIds
        };
        const data = await orderApi.getShippingTimes(option);
        this.deliveryTimeList = data.list;
      } catch (error) {}
    }
  }
};