var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "take-out-order-wrap bg_c_white"
  }, [_c("div", {
    staticClass: "state flex"
  }, _vm._l(_vm.orderState, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "flex state-list flex-align-center cursor color_75",
      class: {
        active: _vm.stateActive === item.id
      },
      on: {
        click: function ($event) {
          return _vm.handleState(item.id);
        }
      }
    }, [_c("div", [_vm._v(_vm._s(item.title))]), index !== _vm.orderState.length - 1 ? _c("div", {
      staticClass: "line"
    }) : _vm._e()]);
  }), 0), _c("div", {
    staticClass: "order-list padding-t-5"
  }, [_c("order-list", {
    attrs: {
      list: _vm.list,
      page: _vm.page,
      pageTotal: _vm.pageTotal
    },
    on: {
      toOrderDetails: _vm.toOrderDetails,
      openDailog: _vm.openTooltipDailog,
      refresh: _vm.refreshList
    }
  })], 1), _vm.showTipDialog ? _c("tooltip-dialog", {
    attrs: {
      title: "温馨提示",
      details: "是否删除该订单？"
    },
    on: {
      cancel: _vm.closeTisDialog,
      confirm: _vm.delOrder,
      closeDialog: _vm.closeTisDialog
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };