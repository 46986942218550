/*
 * @Descripttion: 订单接口文件，使用时import对应的文件
 * @Date: 2022-05-20 10:23:43
 * @LastEditTime: 2022-06-07 10:11:57
 */
import request from '@/common/request';
import $config from '@/config';
export default {
  /**
  * @description 用户订单列表
  */
  orderList(params) {
    return request({
      url: '/web/user/order',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 删除订单
  */
  orderDelete(params) {
    return request({
      url: '/web/user/orderDelete',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  }
};