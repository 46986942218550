import "core-js/modules/es.array.push.js";
import addressApi from '../../api/home/address';
export default {
  name: 'AddressModule',
  props: {
    title: {
      type: String,
      default: '收货地址'
    },
    deliveryFee: {
      type: String,
      default: '收货地址'
    },
    activeAddressId: {
      type: [String, Number],
      default: '' || 0
    }
  },
  data() {
    return {
      addressActive: 1,
      isShow: true,
      params: {
        name: '',
        mobile: '',
        sex: '',
        way: '',
        wechatId: '',
        addr: '',
        houseNum: '',
        sexArr: ['女士'],
        sexList: [{
          id: 1,
          name: '先生'
        }, {
          id: 2,
          name: '女士'
        }],
        wayArr: [],
        wayList: [{
          id: 1,
          name: '只能电话联系，APP收不到消息',
          checked: false
        }, {
          id: 2,
          name: '只能APP通知，不需要电话联系',
          checked: false
        }]
      },
      uAddressId: '',
      // 用户地址id
      addrs: [],
      //用户地址列表
      uAddressList: [],
      // 用户地址列表
      selAddressId: '',
      // 选中的地址id
      dialogAddress: false,
      // 地址弹窗
      dialogAddressList: false,
      // 地址列表弹窗
      addressDialogType: 'add',
      mapParams: {},
      isChange: false
    };
  },
  created() {
    this.getUAddressList();
  },
  computed: {
    uAddressListShow: {
      get() {
        if (this.isShow) {
          if (this.uAddressList.length < 1) {
            return this.uAddressList;
          }
          let newArr = [];
          for (let i = 0; i < this.uAddressList.length; i++) {
            const item = this.uAddressList[i];
            if (item.checked) {
              newArr.push(item);
            }
          }
          return newArr;
        }
        return this.uAddressList;
      },
      set(val) {
        this.uAddressListShow = val;
      }
    }
  },
  methods: {
    editAddress(id) {
      this.addressDialogType = 'edit';
      let targetAddress = this.uAddressList.find(item => {
        return item.id === id;
      });
      this.params.name = targetAddress.consigner;
      this.params.sex = targetAddress.sex;
      this.params.sexArr[0] = targetAddress.sex === 1 ? this.params.sexList[0].name : this.params.sexList[1].name;
      this.params.mobile = targetAddress.mobile;
      this.params.way = targetAddress.type;
      this.params.wayArr[0] = targetAddress.type === 1 ? this.params.wayList[0].name : this.params.wayList[1].name;
      this.params.addr = targetAddress.address;
      this.params.houseNum = targetAddress.door_no;
      this.params.wechatId = targetAddress.wechat;
      this.params.need_edit = targetAddress.need_edit;
      this.mapParams.lat = targetAddress.lat;
      this.mapParams.lon = targetAddress.lon;
      this.selAddressId = id;
      this.dialogAddress = true;
      setTimeout(() => {
        this.openDialogAddressList();
      }, 50);
    },
    // 显示/隐藏更多地址
    showMore() {
      this.isShow = !this.isShow;
    },
    // 选择地址
    checkAddress(obj) {
      for (let i = 0; i < this.uAddressList.length; i++) {
        const item = this.uAddressList[i];
        if (item.id === obj.id) {
          this.$set(this.uAddressList[i], 'checked', true);
        } else {
          this.$set(this.uAddressList[i], 'checked', false);
        }
      }
      this.isShow = true;
      this.selAddressId = obj.id;
      this.getAddressId(false, obj.need_edit);
    },
    // 打开地址弹窗
    openAddressDialog() {
      this.addressDialogType = 'add';
      // 清空数据
      this.params.name = '';
      this.params.mobile = '';
      this.params.sex = '';
      this.params.way = '';
      this.params.wechatId = '';
      this.params.addr = '';
      this.selAddressId = '';
      this.params.houseNum = '';
      this.params.wayArr = [];
      this.params.sexArr = [];
      this.addreddTitle = '新增';
      this.params.sexArr = ['女士'];
      this.params.sex = this.params.sexArr[0] === '先生' ? '1' : '2';
      this.dialogAddress = true;
      this.isChange = false;
    },
    // 选择性别
    sexChange() {
      if (this.params.sexArr.length > 1) {
        this.params.sexArr.shift();
      }
      this.params.sex = this.params.sexArr[0] === '先生' ? '1' : '2';
    },
    // 选择通知方式
    wayChange() {
      if (this.params.wayArr.length > 1) {
        this.params.wayArr.shift();
      }
      this.params.way = this.params.wayArr[0] === this.params.wayList[0].name ? '1' : '2';
    },
    // 打开系统地址列表弹窗
    openDialogAddressList() {
      this.dialogAddressList = true;
    },
    // 获取用户地址列表
    async getUAddressList() {
      try {
        this.addrs = await addressApi.addressIndex();
        for (let i = 0; i < this.addrs.length; i++) {
          const data = this.addrs[i];
          if (!data.checked) {
            this.$set(this.addrs[i], 'checked', false);
          }
          if (data.is_default == 1) {
            this.$set(this.addrs[i], 'checked', true);
            this.selAddressId = data.id;
            this.getAddressId(true, data.need_edit);
          }

          // 存在传进来的地址并且用户没默认地址时
          setTimeout(() => {
            if (data.is_default == 0 && data.id == this.activeAddressId) {
              this.$set(this.addrs[i], 'checked', true);
              this.selAddressId = data.id;
              this.getAddressId(true, data.need_edit);
            }
          }, 1200);
        }
        this.uAddressList = [...this.addrs];
      } catch (error) {}
    },
    getAddressId(isFirst, isNeedEdit) {
      this.$emit('getAddressId', this.selAddressId, isFirst);
      this.$emit('getIsNeedEdit', isNeedEdit);
    },
    // 选择系统列表地址，赋值到收货地址模块
    selAddress(obj) {
      this.params.addr = obj.address;
      this.dialogAddressList = false;
      this.mapParams = obj;
      this.isChange = true;
      this.getAddressId(true, obj.need_edit);
    },
    // 新增用户地址列表
    async saveAddress() {
      if (!this.params.name) {
        return this.$message.error('请填写收货人姓名');
      }
      if (!this.params.sex) {
        return this.$message.error('请选择性别');
      }
      if (!this.params.mobile) {
        return this.$message.error('请填写当地手机号');
      }
      if (this.params.wayArr.length === 0) {
        return this.$message.error('请选择通知方式');
      }
      if (!this.params.addr) {
        return this.$message.error('请选择收货人位置信息');
      }
      const option = {
        consigner: this.params.name,
        sex: this.params.sex,
        mobile: this.params.mobile,
        type: this.params.way,
        address_id: '',
        door_no: this.params.houseNum,
        wechat: this.params.wechatId,
        lat: this.mapParams.lat,
        lon: this.mapParams.lon || this.mapParams.lng,
        address: this.params.addr,
        id: this.addressDialogType != 'add' ? this.selAddressId : 0
      };
      try {
        const data = await addressApi.storeAddress(option);
        this.dialogAddress = false;
        this.getUAddressList();
        this.$message.success(`${this.addressDialogType === 'add' ? '新增' : '编辑'}地址成功`);
      } catch (error) {}
    }
  }
};