var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "edit-address-dialog-wrap"
  }, [_c("el-dialog", {
    staticClass: "add-address",
    attrs: {
      title: `${_vm.addreddTitle}收货地址`,
      visible: _vm.dialogVisible,
      width: "31%",
      "before-close": _vm.beforeClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.closeDialog
    }
  }, [_c("div", [_c("div", {
    staticClass: "flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "flex flex-align-center"
  }, [_c("div", {
    staticClass: "color_75 w-75"
  }, [_c("span", {
    staticClass: "color_red"
  }, [_vm._v("*")]), _vm._v("联 系 人 ")]), _c("el-input", {
    staticClass: "bg_F5 input-wrap",
    attrs: {
      placeholder: "请填写收货人姓名"
    },
    model: {
      value: _vm.params.name,
      callback: function ($$v) {
        _vm.$set(_vm.params, "name", $$v);
      },
      expression: "params.name"
    }
  })], 1), _c("el-checkbox-group", {
    staticClass: "flex",
    on: {
      change: _vm.sexChange
    },
    model: {
      value: _vm.params.sexArr,
      callback: function ($$v) {
        _vm.$set(_vm.params, "sexArr", $$v);
      },
      expression: "params.sexArr"
    }
  }, _vm._l(_vm.params.sexList, function (item, index) {
    return _c("el-checkbox", {
      key: index,
      staticClass: "color_75",
      attrs: {
        label: item.name
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticClass: "flex flex-align-center margin-t-15"
  }, [_c("div", {
    staticClass: "color_75 w-75"
  }, [_c("span", {
    staticClass: "color_red"
  }, [_vm._v("*")]), _vm._v("手 机 号 ")]), _c("el-input", {
    staticClass: "bg_F5 input-wrap",
    attrs: {
      placeholder: "请填写当地手机号，非当地手机号无法提交"
    },
    model: {
      value: _vm.params.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.params, "mobile", $$v);
      },
      expression: "params.mobile"
    }
  })], 1), _c("div", {
    staticClass: "flex margin-t-15"
  }, [_c("div", {
    staticClass: "color_75 w-75"
  }, [_c("span", {
    staticClass: "color_red"
  }, [_vm._v("*")]), _vm._v("通知方式 ")]), _c("el-checkbox-group", {
    staticClass: "flex flex-column",
    on: {
      change: _vm.wayChange
    },
    model: {
      value: _vm.params.wayArr,
      callback: function ($$v) {
        _vm.$set(_vm.params, "wayArr", $$v);
      },
      expression: "params.wayArr"
    }
  }, _vm._l(_vm.params.wayList, function (item, index) {
    return _c("el-checkbox", {
      key: index,
      staticClass: "color_75",
      class: {
        "margin-b-10": index !== _vm.params.wayList.length - 1
      },
      attrs: {
        label: item.name
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticClass: "flex flex-align-center margin-t-15"
  }, [_c("div", {
    staticClass: "color_75 w-75"
  }, [_vm._v(" 微 信 号 ")]), _c("el-input", {
    staticClass: "bg_F5 input-wrap",
    attrs: {
      placeholder: "（选填）方便客服联系您"
    },
    model: {
      value: _vm.params.wechatId,
      callback: function ($$v) {
        _vm.$set(_vm.params, "wechatId", $$v);
      },
      expression: "params.wechatId"
    }
  })], 1), _c("div", {
    staticClass: "flex flex-align-center margin-t-15"
  }, [_c("div", {
    staticClass: "color_75 w-75"
  }, [_c("span", {
    staticClass: "color_red"
  }, [_vm._v("*")]), _vm._v("收货地址 ")]), _c("el-input", {
    staticClass: "bg_F5 input-wrap",
    attrs: {
      "suffix-icon": "el-icon-arrow-down",
      placeholder: "请选择收货人位置信息"
    },
    on: {
      focus: _vm.openDialogAddressList
    },
    nativeOn: {
      click: function ($event) {
        return _vm.openDialogAddressList.apply(null, arguments);
      }
    },
    model: {
      value: _vm.params.addr,
      callback: function ($$v) {
        _vm.$set(_vm.params, "addr", $$v);
      },
      expression: "params.addr"
    }
  })], 1), _c("div", {
    staticClass: "flex flex-align-center margin-t-15"
  }, [_c("div", {
    staticClass: "color_75",
    staticStyle: {
      "min-width": "75px"
    }
  }, [_vm._v(" 门 牌 号 ")]), _c("el-input", {
    staticClass: "bg_F5 input-wrap",
    attrs: {
      placeholder: "请勿输入中文，骑手看不懂会影响配送"
    },
    model: {
      value: _vm.params.houseNum,
      callback: function ($$v) {
        _vm.$set(_vm.params, "houseNum", $$v);
      },
      expression: "params.houseNum"
    }
  })], 1)]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "btn-confirm",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("保存")])], 1)]), _c("el-dialog", {
    attrs: {
      visible: _vm.addressConfirm,
      width: "28%",
      top: "30vh",
      center: ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.addressConfirm = $event;
      }
    }
  }, [_c("div", [_vm._v("确定当前地址准确无误？")]), _c("span", {
    staticClass: "confirm-address",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.save
    }
  }, [_vm._v("确定")]), _c("el-button", {
    on: {
      click: function ($event) {
        _vm.addressConfirm = false;
      }
    }
  }, [_vm._v("取消")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };