var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "register-dialog-wrap"
  }, [_c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      width: "25%",
      center: ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.closeDialog
    }
  }, [_c("div", {
    staticClass: "font-s-18 color_3 cursor",
    on: {
      click: _vm.back
    }
  }, [_c("i", {
    staticClass: "el-icon-back"
  }), _vm._v(" 注册 ")]), _c("div", {
    staticClass: "content-wrap"
  }, [_c("div", {
    staticClass: "margin-tb-15"
  }, [_c("el-input", {
    staticClass: "bg_F5",
    class: _vm.params.showUserName ? "bg_red" : "bg_F5",
    attrs: {
      placeholder: "请输入用户名（至少六位数字或字母）"
    },
    on: {
      blur: _vm.errBlur
    },
    model: {
      value: _vm.params.userName,
      callback: function ($$v) {
        _vm.$set(_vm.params, "userName", $$v);
      },
      expression: "params.userName"
    }
  }), _vm.params.showUserName ? _c("div", {
    staticClass: "padding-t-10 padding-b-5 font-s-12 color_red"
  }, [_vm._v("请输入用户名（至少六位数字或字母）")]) : _vm._e()], 1), _c("div", {
    staticClass: "margin-b-15"
  }, [_c("div", {
    staticClass: "flex flex-align-center position-re bor-r-4",
    class: _vm.params.showMobile ? "mobile_red" : "bg_F5"
  }, [_c("el-dropdown", {
    staticStyle: {
      "min-width": "85px"
    }
  }, [_c("div", {
    staticClass: "el-dropdown-link cursor padding-l-10"
  }, [_vm._v(" +" + _vm._s(_vm.defaultCode) + " "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    staticClass: "league-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.mobiles, function (item, index) {
    return _c("el-dropdown-item", {
      key: index,
      nativeOn: {
        click: function ($event) {
          return _vm.handleAreaCode(item);
        }
      }
    }, [_vm._v("+" + _vm._s(item.areaCode))]);
  }), 1)], 1), _c("el-input", {
    staticClass: "input-wrap",
    attrs: {
      placeholder: "请输入手机号",
      oninput: "if(value.length > 11) value = value.slice(0,11)",
      type: "number",
      max: "11"
    },
    on: {
      blur: _vm.errBlur
    },
    model: {
      value: _vm.params.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.params, "mobile", $$v);
      },
      expression: "params.mobile"
    }
  })], 1), _vm.params.showMobile ? _c("div", {
    staticClass: "padding-t-10 padding-b-5 font-s-12 color_red"
  }, [_vm._v("请输入正确的手机号码")]) : _vm._e()]), _c("div", {
    staticClass: "margin-b-15"
  }, [_c("div", {
    staticClass: "flex input-wrap position-re flex-between",
    class: _vm.params.showCode ? "bg_red" : "bg_F5"
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入验证码",
      type: "text"
    },
    model: {
      value: _vm.params.code,
      callback: function ($$v) {
        _vm.$set(_vm.params, "code", $$v);
      },
      expression: "params.code"
    }
  }), _c("div", {
    staticClass: "code-tips flex-middle cursor font-s-12 position-ab cursor",
    class: _vm.time < 60 ? "color_B0" : "color_primary",
    on: {
      click: _vm.sendCode
    }
  }, [_vm._v(_vm._s(_vm.time < 60 ? "重新发送" + _vm.time + "s" : "获取验证码"))])], 1), _vm.params.showCode ? _c("div", {
    staticClass: "padding-t-10 padding-b-5 font-s-12 color_red"
  }, [_vm._v("验证码错误")]) : _vm._e()]), _c("div", {
    staticClass: "margin-b-10"
  }, [_c("el-input", {
    class: _vm.params.showPassWord ? "bg_red" : "bg_F5",
    attrs: {
      placeholder: "密码为8～16位数字和字母组成",
      type: "password"
    },
    on: {
      blur: _vm.errBlur
    },
    model: {
      value: _vm.params.passWord,
      callback: function ($$v) {
        _vm.$set(_vm.params, "passWord", $$v);
      },
      expression: "params.passWord"
    }
  }), _vm.params.showPassWord ? _c("div", {
    staticClass: "padding-t-10 font-s-12 color_red"
  }, [_vm._v("密码为8～16位数字和字母组成")]) : _vm._e()], 1)]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "btn-confirm margin-tb-10 w-100",
    attrs: {
      disabled: _vm.disabled,
      type: "primary"
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v("确定")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };