import Notify from '@wcjiang/notify';
import { objKeySort } from '@/utils/index';
import md5 from 'js-md5';
import Axios from 'axios';
import TIM from 'tim-js-sdk';
export default {
  name: 'SidebarBtn',
  props: {
    showBtn: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: String,
      default: '0px'
    },
    name: {
      type: String,
      default: '回顶部'
    },
    icon: {
      type: String,
      default: 'top@2x'
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      userID: this.profile && this.profile.im_info.tencent_user_id || '',
      userSig: this.profile && this.profile.im_info.tencent_user_sig || '',
      tim: null,
      notify: {},
      uid: this.profile && this.profile.uid || ''
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    profile() {
      return this.$store.state.user.profile;
    },
    noticeCounts() {
      return this.$store.state.user.noticeCounts;
    }
  },
  watch: {
    value: {
      handler(val) {
        return this.value = val;
      },
      immediate: true
    }
  },
  mounted() {
    this.tim = TIM.create({
      SDKAppID: window.genTestUserSig(this.userID).SDKAppID
    });
    this.tim.setLogLevel(4);

    // 初始化获取用户IM消息数量
    this.$bus.$on('getCountsInit', () => {
      this.getCounts(false);
    });
    this.$nextTick(() => {
      this.notify = new Notify({
        audio: {
          file: require('@/assets/audio/msg_tip.mp3')
        },
        // 可选，默认绿底白字的 Favicon
        updateFavicon: {
          // favicon 字体颜色
          textColor: '#fff',
          // 背景颜色，设置背景颜色透明，将值设置为“transparent”
          backgroundColor: 'transparent'
        }
      });
    });

    // 请求获取IM登录信息、路由接口数据
    this.$bus.$on('getPublicParams', () => {
      this.publicParams(this.profile.uid);
    });
  },
  methods: {
    // 经过侧边栏事件
    handleHover() {
      this.$emit('handleHover');
    },
    // 离开侧边栏事件
    handleOut() {
      this.$emit('handleOut');
    },
    // 初始化im
    initIm() {
      this.loginIm();
    },
    // 登录im
    loginIm() {
      if (this.userID) {
        this.tim.login({
          userID: this.userID,
          userSig: this.userSig
        }).then(() => {
          // 收到新消息
          this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
        }).catch(error => {
          console.log('登录失败：', error);
        });
      }
    },
    onReceiveMessage() {
      this.getCounts(true);
    },
    // 播放音频文件、打开提示框
    speckAudio(noticeCounts) {
      // 播放音频文件
      this.notify.player();
      const notify = this.$notify({
        title: '提示',
        dangerouslyUseHTMLString: true,
        message: `<p style="cursor: pointer;">您有<span style="color: red;">${noticeCounts}</span>条未读消息，请前往查看</p>`,
        offset: 0,
        customClass: 'notify-style-im',
        duration: 5000
      });

      // 点击消息内容
      notify.$el.querySelector('p').onclick = () => {
        // 打开IM会话
        window.open(this.profile.im_info.web_im_url);

        // 点击后关闭notify
        notify.close();
      };
    },
    // 获取某个用户的小红点数量
    getCounts(isTips) {
      // const baseUrl = 'http://oimapi.kuailife888.com' // 测试 - 新
      // const baseUrl = 'http://imapi2.kuailife365.com' // 预发布 - 新
      const baseUrl = 'https://imapi.kuailife123.com'; // 正式 - 新

      const p = {
        type: 'client',
        module_type: '0',
        uid: this.profile.uid,
        ftype: 'web',
        timestamp: parseInt(new Date().getTime() / 1000)
      };
      const formData = new FormData();
      formData.append('type', 'client');
      formData.append('module_type', '0');
      formData.append('uid', this.profile.uid);
      formData.append('timestamp', parseInt(new Date().getTime() / 1000));
      formData.append('ftype', 'web');
      const requesData = p || {}; // 接口参数对象
      const newObj = objKeySort(requesData, false);
      let sign = ''; // 加密参数
      Object.keys(newObj).forEach(function (key) {
        sign += key + '=' + newObj[key] + '&';
      });
      const signKey = sign + 'f0bc6fae953233ab';

      // md5加密
      const md5Data = md5(signKey);
      formData.append('sign', md5Data);
      Axios.post(baseUrl + '/api/Message/getCounts', formData).then(res => {
        this.$store.commit("noticeCounts", res.data.data.chat_counts); // 将value存储到key字段
        if (isTips && res.data.data.chat_counts * 1 > 0) {
          this.value = res.data.data.chat_counts * 1;
          this.speckAudio(res.data.data.chat_counts);
        }
      });
    },
    // 点击侧边栏事件 打开IM客服聊天页面
    handleClick() {
      if (this.name === '客服中心') {
        if (this.token) {
          // TODO 已登录，跳转到在线客服
          if (this.profile.im_info.web_im_url) {
            window.open(this.profile.im_info.web_im_url);
          } else {
            this.publicParams();
          }
        } else {
          this.$bus.$emit('openDialog', 'login');
        }
      } else {
        this.$emit('handleClick');
      }
    },
    // 获取IM登录信息、路由
    publicParams() {
      Axios({
        method: "post",
        url: "/api/Takeout/publicParams",
        data: {
          uid: this.profile.uid,
          type: 'client'
        }
      }).then(res => {
        if (res.data.code == 200) {
          // 获取IM登录数据
          window.localStorage.setItem("tencent_user_sig", res.data.data.tencent_user_sig);
          window.localStorage.setItem("tencent_user_id", res.data.data.tencent_user_id);
          window.localStorage.setItem("web_im_url", res.data.data.web_im_url);
          this.userID = res.data.data.tencent_user_id;
          this.userSig = res.data.data.tencent_user_sig;
          this.web_im_url = res.data.data.web_im_url;
          this.initIm();
          window.open(res.data.data.web_im_url);
        }
      });
    }
  }
};