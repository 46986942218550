/*
 * @Descripttion: 淘淘主页
 * @Date: 2022-04-06 13:37:31
 * @LastEditTime: 2022-04-06 13:58:18
 */
import Layout from '@/views/layout';
export default [{
  path: '/taotao',
  component: Layout,
  redirect: '/taotao/index',
  children: [{
    path: 'index',
    name: 'Index',
    component: () => import('@/views/taotao/index'),
    meta: {
      title: '淘淘'
    }
  }]
}];