var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-header"
  }, [_c("div", {
    staticClass: "business-header-wrap"
  }, [_c("div", {
    staticClass: "business-header-content flex"
  }, [_c("div", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("img", {
    staticClass: "logo",
    attrs: {
      src: require("@/assets/logo@2x.png"),
      alt: "logo"
    },
    on: {
      click: function ($event) {
        return _vm.skip("https://www.kuaizilife.com", 1);
      }
    }
  })]), _c("div", {
    staticClass: "menu-wrap flex"
  }, _vm._l(_vm.menuList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "menu",
      class: {
        active: _vm.currentMenu === index
      },
      on: {
        click: function ($event) {
          return _vm.skip(item.path, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "btn-area"
  }, [_c("div", {
    staticClass: "h-100 flex-middle"
  }, [!_vm.showPhoneCode ? _c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/phone@2x.png")
    },
    on: {
      mouseover: _vm.showPhone
    }
  }) : _c("el-dropdown", {
    staticClass: "flex"
  }, [_c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/phone2@2x.png")
    },
    on: {
      mouseout: _vm.showPhone
    }
  }), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", [_c("img", {
    staticClass: "code",
    staticStyle: {
      "code width": "150px",
      height: "150px",
      margin: "0 -10px -10px"
    },
    attrs: {
      src: require("@/assets/qrcode.png")
    }
  })])], 1)], 1), !_vm.token ? [!_vm.showUser ? _c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/pcenter@2x.png")
    },
    on: {
      click: _vm.handleUser
    }
  }) : _c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/icons/pcenter2@2x.png")
    },
    on: {
      click: _vm.handleUser
    }
  })] : _vm._e(), _vm.token ? [_c("el-dropdown", [_c("div", {
    staticClass: "hover_pointer flex flex-align-center"
  }, [_vm.profile.avatar ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.profile.avatar
    }
  }) : _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.$config.DEFAULT_AVATAR
    }
  }), _c("span", {
    staticClass: "username margin-l-5",
    class: {
      color_primary: _vm.activeUser
    }
  }, [_vm._v(_vm._s(_vm.profile.nickname ? _vm.profile.nickname : _vm.profile.username))])]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    nativeOn: {
      click: function ($event) {
        return _vm.toUser.apply(null, arguments);
      }
    }
  }, [_vm._v("个人中心")]), _c("el-dropdown-item", {
    nativeOn: {
      click: function ($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_vm._v("退出登录")])], 1)], 1)] : _vm._e()], 2)])])]), _vm.showUser ? [_c("login-dialog", {
    on: {
      openPassWordDialog: _vm.handlePassWord,
      openRegisterDialog: _vm.handleRegister,
      confirmLogin: _vm.confirmLogin,
      closeDialog: _vm.handleUser
    }
  })] : _vm._e(), _vm.passwordDialog ? [_c("update-password-dialog", {
    on: {
      back: _vm.back,
      confirm: _vm.confirmUpdate,
      closeDialog: _vm.handlePassWord
    }
  })] : _vm._e(), _vm.registerDialog ? [_c("register-dialog", {
    on: {
      back: _vm.back,
      confirm: _vm.register,
      closeDialog: _vm.handleRegister
    }
  })] : _vm._e(), _vm.dialogVisible ? _c("tooltip-dialog", {
    attrs: {
      title: "提示",
      showCancel: false,
      details: "功能开发中，可下载筷子生活APP使用",
      loadingBtnText: "确认"
    },
    on: {
      cancel: function ($event) {
        _vm.dialogVisible = false;
      },
      confirm: _vm.openOfficial
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };