/*
 * @Descripttion: 外卖端路由
 * @Date: 2022-04-06 13:37:31
 * @LastEditTime: 2022-07-20 11:19:29
 */
import Layout from '@/views/layout';
export default [{
  path: '/waimai',
  component: Layout,
  redirect: '/waimai/index',
  children: [{
    path: 'index',
    name: 'Index',
    component: () => import('@/views/waimai/index'),
    meta: {
      title: '外卖'
    }
  }, {
    path: '/waimai/cart',
    name: 'Cart',
    component: () => import('@/views/waimai/cart'),
    meta: {
      title: '购物车'
    }
  }, {
    path: '/waimai/shopDetails',
    name: 'ShopDetails',
    component: () => import('@/views/waimai/shopDetails'),
    meta: {
      title: '店铺详情'
    }
  }, {
    path: '/waimai/orderSave',
    name: 'OrderSave',
    component: () => import('@/views/waimai/orderSave'),
    meta: {
      title: '创建订单'
    }
  }]
}];