import homeApi from '../../api/home/index';
import { mobileRegular } from '../../utils/index';
export default {
  name: 'LoginDialog',
  // props: {
  //   dialogVisible: {
  //     type: Boolean,
  //     default: false
  //   },
  // },
  data() {
    return {
      dialogVisible: true,
      params: {
        mobile: '',
        passWord: '',
        code: '',
        showCode: false
      },
      title: '账号登录',
      tipsTitle: '手机动态码登录',
      mobiles: this.$store.state.user.mobiles,
      defaultCode: '63',
      // 默认区号
      areaCodeObj: this.$store.state.user.mobiles[1],
      redBg: false,
      time: 60,
      sending: false
    };
  },
  methods: {
    // 关闭弹窗事件
    closeDialog() {
      this.$emit('closeDialog');
    },
    // 选择区号
    handleAreaCode(obj) {
      this.areaCodeObj = obj;
      this.defaultCode = obj.areaCode;
    },
    // 登录
    confirmLogin() {
      if (!this.params.mobile && !this.params.passWord) {
        return this.$message.error('请输入账号密码');
      }
      if (!this.params.passWord && this.title === '账号登录') {
        return this.redBg = true;
      }
      this.$emit('confirmLogin', this.params);
    },
    //  打开注册弹窗
    register() {
      this.$emit('openRegisterDialog');
    },
    // 打开修改密码弹窗
    openPassWordDialog() {
      this.$emit('openPassWordDialog');
    },
    // 切换手机动态码登录/账号密码登录模块
    switchDialog() {
      this.params = {
        mobile: '',
        passWord: '',
        code: '',
        showCode: false
      };
      this.showCode = !this.showCode;
      if (this.showCode) {
        this.title = '手机动态码登录';
        this.tipsTitle = '账号登录';
      } else {
        this.title = '账号登录';
        this.tipsTitle = '手机动态码登录';
      }
    },
    // 验证手机号
    checkPhone() {
      if (!this.params.mobile) {
        this.$message.error('请输入手机号码');
        return false;
      }
      let mobileInfo = {
        country: this.areaCodeObj.country,
        phone: this.params.mobile
      };
      if (!this.params.mobile || !mobileRegular(mobileInfo)) {
        this.$message.warning('请检查区号或手机号是否正确');
        return false;
      }
      return true;
    },
    // 发送验证码
    async sendCode() {
      if (this.sending) {
        return;
      }
      try {
        if (this.time < 60) return;
        let checkRes = this.checkPhone();
        if (!checkRes) return;
        this.sending = true;
        await homeApi.getSmsCode({
          mobile: this.params.mobile
        });
        this.$message.success('发送成功');
        this.countTime();
      } catch (e) {
        this.sending = false;
      }
    },
    // 倒计时
    countTime() {
      clearInterval(this.timeIndex);
      this.timeIndex = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          this.time = 60;
          this.sending = false;
          clearInterval(this.timeIndex);
        }
      }, 1000);
    }
  }
};