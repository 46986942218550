var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "business-footer"
  }, [_c("div", {
    staticClass: "business-footer-content"
  }, [_vm._v(" 软件著作证明 | Copyright © 2015-2023 "), _c("a", {
    staticClass: "business-footer-content-a",
    attrs: {
      href: "https://beian.miit.gov.cn/",
      target: "_blank"
    }
  }, [_vm._v("粤ICP备2021134346号")])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };