/*
 * @Descripttion: 过滤器
 * @Date: 2022-03-30 11:21:02
 * @LastEditTime: 2022-03-30 11:54:35
 */

import Vue from 'vue';
import { format } from '@/utils';

/* 默认保留两位小数 */
Vue.filter('toFixed', function (value, num = 2) {
  if (!value) {
    return '';
  }
  if (typeof value === 'number') {
    return value.toFixed(num);
  }
  return value;
});

/* 日期格式化 */
Vue.filter('format', function (value, rule) {
  if (!value) {
    return '';
  }
  return format(value, rule);
});
Vue.filter('formatTime', function (value, rule) {
  if (!value) {
    return '';
  }
  return format(value, rule);
});

// 数字值初始化处理
Vue.filter('numInit', function (value) {
  if (!value && value !== 0) {
    return '--';
  } else {
    return value;
  }
});