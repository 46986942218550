var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "detail-list-dialog-wrap bg_c_white"
  }, [_c("div", {
    staticClass: "flex flex-align-center flex-between"
  }, [_c("div", {
    staticClass: "color_3 font-s-16"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.title === "余额明细" ? _c("div", {
    staticClass: "flex"
  }, _vm._l(_vm.statusList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "flex flex-align-center"
    }, [_c("div", {
      staticClass: "name font-s-16 cursor",
      class: _vm.statusActive === item.id ? "color_primary" : "color_75",
      on: {
        click: function ($event) {
          return _vm.selStatus(item.id);
        }
      }
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      class: {
        line: index !== _vm.statusList.length - 1
      }
    })]);
  }), 0) : _vm._e()]), _c("el-scrollbar", {
    ref: "scrollRef",
    staticClass: "padding-t-15 detail-list",
    attrs: {
      wrapStyle: "overflow-x: hidden;"
    }
  }, [_vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "list padding-tb-15 cursor",
      class: {
        "list-bottom": index != _vm.list.length - 1
      },
      on: {
        click: function ($event) {
          return _vm.selAddress(item);
        }
      }
    }, [_c("div", {
      staticClass: "flex flex-align-center flex-between w-100"
    }, [_c("div", {
      staticClass: "flex flex-align-center flex-around font-s-14"
    }, [_c("div", {
      staticStyle: {
        width: "160px"
      }
    }, [_vm._v(" " + _vm._s(_vm.title === "余额明细" ? _vm.$moment(item.create_time * 1000).format("YYYY-MM-DD") : item.date) + " ")]), _c("div", {
      staticStyle: {
        width: "200px"
      }
    }, [_vm._v(_vm._s(item.source))]), _c("div", {
      staticClass: "color_75",
      staticStyle: {
        width: "376px"
      }
    }, [_vm._v(_vm._s(_vm.title === "余额明细" ? item.text : item.order_no ? "订单号：" + item.order_no : ""))])]), _c("div", {
      staticStyle: {
        width: "114px"
      }
    }, [_vm.title === "余额明细" ? _c("div", {
      staticClass: "font-s-14 flex flex-end",
      class: item.value * 1 > 0 ? "color_primary" : "color_3"
    }, [_vm._v(_vm._s(item.value * 1 > 0 ? item.value : item.value + "₱"))]) : _c("div", {
      staticClass: "font-s-14 flex flex-end",
      class: item.score * 1 > 0 ? "color_primary" : "color_3"
    }, [_vm._v(_vm._s(item.score))]), _vm.title === "余额明细" ? _c("div", {
      staticClass: "font-s-12 color_75 padding-t-5 flex flex-end"
    }, [_vm._v(" 余额：" + _vm._s(item.current_balance) + "₱ ")]) : _vm._e()])])]);
  }), _c("bottom-tip", {
    attrs: {
      list: _vm.list,
      page: _vm.page,
      pageTotal: _vm.pageTotal
    }
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };