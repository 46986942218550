import Layout from '@/views/layout';
export default [{
  path: '/',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Index',
    component: () => import('@/views/home/index'),
    meta: {
      title: '首页'
    }
  }]
}];