import addressApi from '../../api/home/address';
export default {
  name: 'AddressListDialog',
  data() {
    return {
      dialogVisible: true,
      page: 1,
      pageTotal: 0,
      keyword: '',
      list: [],
      showTipDialog: false,
      addressId: '',
      isMap: false
    };
  },
  props: {
    address: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.keyword = this.address;
    this.getAddressList();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 选择地址
    selAddress(obj) {
      // this.addressId = obj.id
      this.$emit('selAddress', obj);
      this.showTipDialog = true;
    },
    // 关闭弹窗事件
    closeDialog() {
      this.$emit('closeDialog');
    },
    // 搜索地址
    search() {
      this.page = 1;
      this.list = [];
      this.getAddressList();
    },
    // 获取系统地址列表
    async getAddressList() {
      const option = {
        page: this.page,
        keyword: this.keyword
      };
      try {
        const data = await addressApi.addressList(option);
        this.list = this.list.concat(data.address_list);
        this.pageTotal = data.last_page;
      } catch (error) {}
    },
    // 触底加载更多
    handleScroll(e) {
      // 获取滚动的距离
      let scrollTop = e.target.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      let clientHeight = e.target.clientHeight;
      // 获取屏幕(浏览器)高度
      let scrollHeight = e.target.scrollHeight;
      // 如果滚动到接近底部，自动加载下一页
      if (this.page < this.pageTotal && scrollTop + clientHeight - scrollHeight >= 0) {
        // 事件处理
        this.page++;
        this.getAddressList();
      }
    },
    // 打开谷歌地图
    getGppgleAddress() {
      this.$emit('getGppgleAddress');
    },
    cancelMap() {
      this.dialogVisible = false;
    }
  }
};