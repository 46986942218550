import homeApi from '../../api/home/index';
export default {
  name: 'DetailList',
  props: {
    title: {
      type: String,
      default: ''
    }
    // list: {
    //   type: Array,
    //   default: () => []
    // },
  },
  data() {
    return {
      page: 1,
      pageSize: 15,
      pageTotal: 0,
      list: [],
      statusActive: '',
      statusList: [{
        id: '',
        name: '全部'
      }, {
        id: 'consume',
        name: '充值'
      }, {
        id: 'recharge',
        name: '消费'
      }]
    };
  },
  created() {
    this.getListData();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 选择状态
    selStatus(id) {
      this.statusActive = id;
      this.list = [];
      this.page = 1;
      this.pageTotal = 0;
      this.getListData();
    },
    // 用户账户余额记录
    async getBalanceData() {
      try {
        const option = {
          type: this.statusActive,
          page: this.page,
          page_size: '15'
        };
        const data = await homeApi.balance(option);
        this.$emit('balanceNum', data.balance);
        this.list = this.list.concat(data.logs);
        this.pageTotal = data.last_page;
      } catch (error) {}
    },
    // 用户会员积分记录
    async getScoreData() {
      try {
        const option = {
          page: this.page,
          page_size: '15'
        };
        const data = await homeApi.score(option);
        this.list = this.list.concat(data.logs);
        this.pageTotal = data.last_page;
      } catch (error) {}
    },
    // 用户账户余额记录
    async getIntegerData() {
      try {
        const option = {
          type: '',
          page: this.page,
          page_size: '15'
        };
        const data = await homeApi.integer(option);
        this.list = this.list.concat(data.logs);
        this.pageTotal = data.last_page;
      } catch (error) {}
    },
    getListData() {
      if (this.title === '余额明细') {
        this.getBalanceData();
      } else {
        this.getScoreData();
      }
    },
    // 触底加载更多
    handleScroll(e) {
      // 获取滚动的距离
      let scrollTop = e.target.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      let clientHeight = e.target.clientHeight;
      // 获取屏幕(浏览器)高度
      let scrollHeight = e.target.scrollHeight;
      // 如果滚动到接近底部，自动加载下一页
      if (this.page < this.pageTotal && scrollTop + clientHeight - scrollHeight >= 0) {
        // 事件处理
        this.page++;
        this.getListData();
      }
    }
  }
};