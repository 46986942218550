export default {
  name: 'SpecificationDialog',
  props: {
    goodsInfo: {
      type: Object,
      default: {}
    },
    activeSku: {
      type: String,
      default: ''
    },
    totalPrice: {
      type: String,
      default: ''
    },
    selSpeci: {
      type: String,
      default: ''
    },
    isShowNum: {
      type: Boolean,
      default: false
    },
    speciNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogVisible: true
    };
  },
  watch: {
    goodsInfo: {
      handler(val) {
        return this.goodsInfo = val;
      },
      immediate: true
    },
    speciNum: {
      handler(val) {
        return this.speciNum = val;
      },
      immediate: true
    }
  },
  methods: {
    addCart() {
      this.$emit('addCart', this.goodsInfo);
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('close');
    },
    // 选择规格
    handleSku(obj) {
      if (obj.stock * 1 === 0) return;
      this.$emit('handleSku', obj);
    },
    // 选择规格属性
    handleAttr(attrIndex, attrObj, obj) {
      this.$emit('handleAttr', attrIndex, attrObj, obj);
    },
    handleNum(type, obj) {
      this.$emit('handleNum', type, obj);
    }
  }
};