/*
 * @Descripttion: 指令
 * @Date: 2022-03-30 11:21:02
 * @LastEditTime: 2022-03-30 11:32:10
 */

import Vue from 'vue';
import store from '@/store';
Vue.directive('auth', {
  inserted: function (el, bind) {
    const val = bind.value;
    const profile = store.state.user.profile;
    if (profile.authorities && profile.authorities.length > 0) {
      let isAuth = false;
      profile.authorities.map(item => {
        if (item.authorityCode === val) {
          isAuth = true;
        }
      });
      if (!isAuth) {
        // 无权限
        el.parentElement.removeChild(el);
      }
    }
  },
  unbind: function () {}
});