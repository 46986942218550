import "core-js/modules/es.array.push.js";
import homeApi from '../../api/home/index';
import orderApi from '../../api/home/order';
import OrderList from '@/components/order/order-list.vue';
export default {
  name: 'PersonalHome',
  components: {
    OrderList
  },
  data() {
    return {
      showTipDialog: false,
      shopInfo: {},
      list: []
    };
  },
  computed: {
    profile() {
      return this.$store.state.user.profile;
    }
  },
  created() {
    this.getOrderList();
    this.getShopList();
  },
  methods: {
    // 刷新列表
    refreshList() {
      this.list = [];
      this.page = 1;
      this.pageTotal = 0;
      this.getOrderList();
      this.getShopList();
    },
    // 用户收藏店铺
    async getShopList() {
      try {
        this.shopInfo = await homeApi.favourite();
      } catch (error) {}
    },
    handleCard(id) {
      this.$router.push({
        path: "/waimai/shopDetails",
        query: {
          shopId: id
        }
      });
    },
    toPage(type) {
      this.$emit('toPage', type);
    },
    // 打开删除订单弹窗
    openTooltipDailog(id) {
      this.orderId = id;
      this.showTipDialog = true;
    },
    // 打开商品详情
    toOrderDetails(id) {
      this.$emit('toOrderDetails', id);
    },
    // 关闭删除订单弹窗
    closeTisDialog() {
      this.showTipDialog = false;
    },
    // 用户订单列表
    async getOrderList() {
      try {
        const option = {
          type: 'takeout',
          status: '',
          page: 1,
          page_size: '3'
        };
        const data = await orderApi.orderList(option);
        this.list = this.list.concat(data.orders);
        // this.pageTotal = data.last_page
      } catch (error) {}
    },
    // 删除订单
    async delOrder() {
      try {
        const option = {
          type: 'takeout',
          id: this.orderId
        };
        const data = await orderApi.orderDelete(option);
        if (data.result == 1) {
          this.$message.success('删除订单成功');
          this.closeTisDialog();
          this.refreshList();
        }
      } catch (error) {}
    }
  }
};