/*
 * @Descripttion: 商城主页
 * @Date: 2022-04-06 13:37:31
 * @LastEditTime: 2022-04-06 13:47:06
 */
import Layout from '@/views/layout';
export default [{
  path: '/shop',
  component: Layout,
  redirect: '/shop/index',
  children: [{
    path: 'index',
    name: 'Index',
    component: () => import('@/views/shop/index'),
    meta: {
      title: '商城'
    }
  }]
}];