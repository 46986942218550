var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "address-list-dialog-wrap"
  }, [_c("el-dialog", {
    staticClass: "address-list-dialog",
    attrs: {
      title: "请选择你的地址",
      visible: _vm.dialogVisible,
      width: "65%",
      top: "10vh"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.closeDialog
    }
  }, [_c("map-com", {
    on: {
      confirmMap: _vm.selAddress,
      cancelMap: _vm.cancelMap
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };