export default {
  name: 'TitlePanel',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    isBtn: {
      type: Boolean,
      default: true
    },
    val: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    // 返回上一级
    back() {
      this.$emit('back');
    }
  }
};