import "core-js/modules/es.array.push.js";
import orderApi from '../../api/waimai/order';
import homeOrderApi from '../../api/home/order';
export default {
  name: 'OrderDetails',
  props: {
    orderId: {
      type: [String, Number],
      default: '' || 0
    },
    orderSourceActive: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      detailsInfo: {},
      time: 900,
      // 十五分钟
      showTipDialog: false,
      showTipDialog: false,
      tipText: '是否取消订单？',
      orderIdVal: this.orderId,
      option: {}
    };
  },
  mounted() {
    if (this.orderId) {
      this.$router.push({
        query: {
          currentCollapse: this.orderSourceActive,
          orderId: this.orderId
        }
      });
    }
    // 刷新当前页面时，获取orderId
    if (this.$route.query.orderId) {
      this.orderIdVal = this.$route.query.orderId;
      this.getOrderDetailInfo();
    }

    // 确认支付
    this.$bus.$off('affirmPayFun');
    this.$bus.$on('affirmPayFun', (obj, type, option) => {
      this.payObj = obj;
      this.paymentTypeVal = type;
      this.confirmPay(option);
    });
  },
  methods: {
    // 获取订单详情数据
    async getOrderDetailInfo() {
      try {
        this.detailsInfo = await orderApi.orderDetail({
          order_id: this.orderIdVal
        });
        // 订单状态：0=待付款，1=等待商家接单，2=等待骑手接单，3=骑手正在配送，4=已完成，5=已取消，6=退款中
        if (this.detailsInfo.order_status == 0 && this.detailsInfo.seconds_remain * 1 > 0) {
          this.time = this.detailsInfo.seconds_remain * 1;
          this.countTime();
        }
      } catch (error) {}
    },
    // 倒计时
    countTime() {
      clearInterval(this.timeIndex);
      this.timeIndex = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.timeIndex);
          this.getOrderDetailInfo();
        }
      }, 1000);
    },
    // 按钮: 1=再来一单,2=取消订单,3=立即支付,5=评价得3积分,6=修改订单,7=打赏,8=完成打赏支付,9=完成差价支付,10=申请售后,12=查看评价,13=删除
    hanleBtn(btnId) {
      switch (btnId) {
        case '1':
          this.oneMoreOrder();
          break;
        case '2':
          this.showTipDialog = true;
          this.tipText = '是否取消订单？';
          break;
        case '3':
          this.$store.commit('user/SET_ISONLINEPAY', true);
          this.$router.push({
            path: this.$route.path,
            query: {
              currentCollapse: this.$route.query.currentCollapse,
              isOnlinePay: true,
              outTradeNo: this.detailsInfo.out_trade_no
            }
          });
          break;
        // case 5:
        //   break;
        // case 10:
        //   break;
        case '12':
          break;
        case '13':
          this.showTipDialog = true;
          this.tipText = '是否删除订单？';
          break;
        default:
          this.$message('功能开发中');
          break;
      }
    },
    // 确认取消/删除订单
    confirm() {
      if (this.tipText == '是否删除订单？') {
        this.delOrder();
      } else {
        this.cancelOrder();
      }
    },
    // 再来一单
    async oneMoreOrder() {
      try {
        await orderApi.oneMoreOrder({
          order_id: this.detailsInfo.order_id
        });
        this.$router.push({
          path: "/waimai/orderSave",
          query: {
            merchantId: this.detailsInfo.merchant_id
          }
        });
      } catch (error) {}
    },
    // 取消订单
    async cancelOrder() {
      try {
        await orderApi.cancelOrder({
          order_id: this.detailsInfo.order_id
        });
        this.$message.success('取消订单成功');
        this.showTipDialog = false;
        setTimeout(() => {
          this.getOrderDetailInfo();
        }, 1000);
      } catch (error) {}
    },
    // 删除订单
    async delOrder() {
      try {
        const option = {
          type: this.orderSourceActive,
          id: this.detailsInfo.order_id
        };
        const data = await homeOrderApi.orderDelete(option);
        if (data.result == 1) {
          this.$message.success('删除订单成功');
          this.showTipDialog = false;
          this.getOrderDetailInfo();
        }
      } catch (error) {}
    },
    confirmPay(option) {
      if (this.payObj.payment_type == 'balance') {
        this.option = option;
        this.getBalance();
      } else {
        this.payOrders();
      }
    },
    // 获取用户余额数据
    async getBalance() {
      try {
        const data = await orderApi.getBalance({
          uid: this.$store.state.user.profile.uid
        });
        // 余额不够
        if (data.balance * 1 < this.detailsInfo.total_money_fee * 1) {
          this.$message.error('您的余额不够，请先联系平台客服充值，或者找兑换的商家兑换余额');
          setTimeout(() => {
            this.$router.push({
              path: "/user/index",
              query: {
                currentCollapse: 'recharge'
              }
            });
          }, 1000);
          return;
        }
        // 存在支付密码
        if (data.checkPayPassword == 1) {
          this.verifyPayPassword();
        }
      } catch (error) {}
    },
    // 校验支付密码
    async verifyPayPassword() {
      try {
        const data = await orderApi.verifyPayPassword({
          payPassword: this.option.password
        });
        if (data.verifyPayPassword == 1) {
          this.payOrders();
        } else {
          this.$message.error('密码错误，请重新输入');
        }
      } catch (error) {}
    },
    // 订单支付
    async payOrders() {
      try {
        const option = {
          out_trade_no: this.detailsInfo.out_trade_no || this.$route.query.outTradeNo,
          payment_type: this.paymentTypeVal,
          payment_hash: this.payObj.payment_hash,
          ftype: 'web'
        };
        const data = await orderApi.payOrders(option);
        if (data.pay_type == 21) {
          if (data.alipay_params.pay_url) {
            window.open(data.alipay_params.pay_url);
            this.$bus.$emit('openIsPayDialog');
            // window.location.href = data.alipay_params.pay_url
          } else {
            this.$message.error(data.alipay_params);
            this.toTakeOutPage();
          }
        } else if (data.pay_type == 20) {
          this.$bus.$emit('openWXPayDialog', data.wxpay_params);
        } else {
          this.$bus.$emit('closeDialog');
          this.$message.success('支付成功');
          this.toTakeOutPage();
        }
      } catch (error) {
        this.toTakeOutPage();
      }
    },
    // 刷新订单详情页
    toTakeOutPage() {
      this.$store.commit('user/SET_ISONLINEPAY', false);
      setTimeout(() => {
        this.$router.push({
          path: this.$route.path,
          query: {
            currentCollapse: this.orderSourceActive,
            orderId: this.orderId
          }
        });
      }, 1000);
    }
  }
};