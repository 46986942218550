/*
 * @Descripttion: 订单接口文件，使用时import对应的文件
 * @Date: 2022-07-12 16:21:45
 * @LastEditTime: 2022-09-14 11:12:41
 */
import request from '@/common/request';
import $config from '@/config';
export default {
  /**
  * @description 获取外卖订单数据
  */
  orderConfirm(params) {
    return request({
      url: '/web/order/orderConfirm',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 获取配送时间
  */
  getShippingTimes(params) {
    return request({
      url: '/api/tuser/getShippingTimes',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 获取优惠券列表
  */
  getCouponLists(params) {
    return request({
      url: '/api/tuser/getCouponLists',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 创建订单
  */
  orderCreate(params) {
    return request({
      url: '/api/tuser/orderCreate',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 查询用户余额
  */
  getBalance(params) {
    return request({
      url: '/api/Member/getBalance',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 校验支付密码
  */
  verifyPayPassword(params) {
    return request({
      url: '/api/Member/verifyPayPassword',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 设置初始支付密码
  */
  setPayPassword(params) {
    return request({
      url: '/api/Member/setPayPassword',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 设置支付密码
  */
  payOrders(params) {
    return request({
      url: '/api/recharge/payOrdersNew',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 再来一单
  */
  oneMoreOrder(params) {
    return request({
      url: '/api/tuser/onemoreorder',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 取消订单
  */
  cancelOrder(params) {
    return request({
      url: '/api/tuser/cancelOrder',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 外卖订单详情
  */
  orderDetail(params) {
    return request({
      url: '/web/order/orderDetail',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 查看充值或提现记录
  */
  cashList(params) {
    return request({
      url: '/web/recharge/cashList',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 支付信息
  */
  getPayInfoNew(params) {
    return request({
      url: '/api/recharge/getPayInfoNew',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 查询订单支付状态
  */
  queryOrderAndUpdate(params) {
    return request({
      url: '/web/pay/queryOrderAndUpdate',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  }
};