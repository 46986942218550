var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "personal-data-wrap bg_c_white"
  }, [_c("div", {
    staticClass: "title color_75"
  }, [_vm._v("个人资料")]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "flex flex-align-center padding-tb-20"
  }, [_c("div", {
    staticClass: "color_3 font-s-16 left"
  }, [_vm._v("头像")]), _c("div", [_vm.profile.avatar ? _c("img", {
    staticClass: "avatar bor-r-50",
    attrs: {
      src: _vm.profile.avatar
    }
  }) : _c("img", {
    staticClass: "avatar bor-r-50",
    attrs: {
      src: _vm.$config.DEFAULT_AVATAR
    }
  })])]), _c("div", {
    staticClass: "flex color_3 font-s-16 padding-tb-20"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("用户名")]), _c("div", [_vm._v(_vm._s(_vm.profile.username))])]), _c("div", {
    staticClass: "flex color_3 font-s-16 padding-tb-20 flex flex-align-center"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("昵称")]), _c("el-input", {
    staticClass: "nickname",
    attrs: {
      placeholder: "姓名"
    },
    model: {
      value: _vm.nickname,
      callback: function ($$v) {
        _vm.nickname = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "nickname"
    }
  })], 1), _c("div", {
    staticClass: "flex color_3 font-s-16 padding-tb-20"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("性别")]), _c("el-checkbox-group", {
    on: {
      change: _vm.sexChange
    },
    model: {
      value: _vm.sexArr,
      callback: function ($$v) {
        _vm.sexArr = $$v;
      },
      expression: "sexArr"
    }
  }, _vm._l(_vm.sexList, function (item, index) {
    return _c("el-checkbox", {
      key: index,
      staticClass: "color_75",
      attrs: {
        label: item.name
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1), _c("div", {
    staticClass: "flex color_3 font-s-16 padding-tb-20"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm._v("我的邀请码")]), _c("div", [_vm._v(_vm._s(_vm.profile.invite_code))])])]), _c("div", {
    staticClass: "text-center btn-box"
  }, [_c("el-button", {
    staticClass: "btn-confirm",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.editUserInfo
    }
  }, [_vm._v("编辑")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };