/*
 * @Descripttion: 地址接口文件，使用时import对应的文件
 * @Date: 2022-05-20 10:23:43
 * @LastEditTime: 2022-09-01 17:51:16
 */
import request from '@/common/request';
import $config from '@/config';
export default {
  /**
  * @description 地址列表
  */
  addressIndex(params) {
    return request({
      url: '/web/address/index',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 删除地址
  */
  destoryAddress(params) {
    return request({
      url: '/web/address/destory',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 删除地址
  */
  storeAddress(params) {
    return request({
      url: '/web/address/store',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 系统地址列表
  */
  addressList(params) {
    return request({
      url: '/web/common/address',
      method: 'GET',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 设置默认地址
  */
  setAddressDefault(params) {
    return request({
      url: '/web/address/setDefault',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  },
  /**
  * @description 获取地址信息
  */
  getAddressInfo(params) {
    return request({
      url: '/web/address/getAddress',
      method: 'POST',
      // 默认为GET
      params,
      domain: $config['DEV_DOMAIN_WAIMAI'],
      // 不传默认用config的配置
      loading: true,
      // 不传默认为true
      headers: {} // 默认为空对象
    });
  }
};