var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "collect-wrap bg_c_white"
  }, [_c("div", {
    staticClass: "title color_75"
  }, [_vm._v("我的收藏")]), _c("div", {
    staticClass: "list"
  }, [_c("recommend-card", {
    attrs: {
      list: _vm.shopList
    },
    on: {
      handleCard: _vm.handleCard
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };