import orderApi from '../../api/home/order';
import OrderList from '@/components/order/order-list.vue';
export default {
  name: 'TakeOutOrder',
  components: {
    OrderList
  },
  data() {
    return {
      // 订单类型
      orderSource: [{
        id: 'takeout',
        title: '外卖订单'
      }, {
        id: 'shop',
        title: '商城订单'
      }, {
        id: 'taotao',
        title: '淘淘订单'
      }],
      orderSourceActive: 'takeout',
      orderState: [{
        id: '',
        title: '全部'
      }, {
        id: 1,
        title: '待付款'
      }, {
        id: 2,
        title: '待收货'
      }, {
        id: 3,
        title: '待评价'
      }, {
        id: 4,
        title: '退款/售后'
      }],
      stateActive: '',
      page: 1,
      list: [],
      pageTotal: 1,
      orderId: '',
      showTipDialog: false,
      time: 900 // 十五分钟
    };
  },
  created() {
    this.getOrderList();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 打开商品详情
    toOrderDetails(id) {
      this.$emit('toOrderDetails', id);
    },
    // 选择订单状态
    handleState(id) {
      this.stateActive = id;
      this.list = [];
      this.page = 1;
      this.pageTotal = 0;
      this.getOrderList();
    },
    // 打开删除订单弹窗
    openTooltipDailog(id) {
      this.orderId = id;
      this.showTipDialog = true;
    },
    // 关闭删除订单弹窗
    closeTisDialog() {
      this.showTipDialog = false;
    },
    // 更新订单列表
    refreshList() {
      this.list = [];
      this.page = 1;
      this.pageTotal = 0;
      this.getOrderList();
    },
    // 用户订单列表
    async getOrderList() {
      try {
        const option = {
          type: this.orderSourceActive,
          status: this.stateActive,
          page: this.page,
          page_size: '15'
        };
        const data = await orderApi.orderList(option);
        this.list = this.list.concat(data.orders);
        this.pageTotal = data.last_page;
        this.list.forEach(item => {
          if (item.status == 0 && item.seconds_remain > 0) {
            this.time = item.seconds_remain;
            this.countTime();
          }
        });
      } catch (error) {}
    },
    // 倒计时
    countTime() {
      clearInterval(this.timeIndex);
      this.timeIndex = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          clearInterval(this.timeIndex);
          this.list = [];
          this.page = 1;
          this.pageTotal = 0;
          this.getOrderList();
        }
      }, 1000);
    },
    // 删除订单
    async delOrder() {
      try {
        const option = {
          type: this.orderSourceActive,
          id: this.orderId
        };
        const data = await orderApi.orderDelete(option);
        if (data.result == 1) {
          this.$message.success('删除订单成功');
          this.closeTisDialog();
          this.page = 1;
          this.list = [];
          this.getOrderList();
        }
      } catch (error) {}
    },
    // 触底加载更多
    handleScroll(e) {
      // 获取滚动的距离
      let scrollTop = e.target.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      let clientHeight = e.target.clientHeight;
      // 获取屏幕(浏览器)高度
      let scrollHeight = e.target.scrollHeight;
      // 如果滚动到接近底部，自动加载下一页
      if (this.page < this.pageTotal && scrollTop + clientHeight - scrollHeight >= 0) {
        // 事件处理
        this.page++;
        this.getOrderList();
      }
    }
  }
};