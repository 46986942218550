const config = {
  TITLE: '筷子生活_原菲度-菲律宾外卖APP,旅游,购物,菲律宾华人本地生活服务',
  // EN_TITLE: 'The Big Smart Screen of the Data Center and Taiwan of the Xinjiang Administration Affairs Bureau',
  EN_TITLE: '',
  ENV: process.env.VUE_APP_MODE || 'dev',
  API_DOMAIN: process.env.VUE_APP_API_DOMAIN,
  // api请求链接，不需要以/结束
  BASE_URL: process.env.VUE_APP_BASE_URL,
  PAGE_SIZE: 20,
  // 全局配置每页获取数据条数
  LAT: '14.591621',
  // 纬度
  LON: '120.999887',
  // 经度
  // 默认头像
  DEFAULT_AVATAR: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAw1BMVEUAAADe3t7j4+Pd3d3j4+Pi4uLj4+Pd3d3d3d3e3t7e3t7e3t7e3t7d3d3d3d3g4ODe3t7c3Nzd3d3d3d3e3t7e3t7e3t7f39/e3t7c3Nze3t7c3Nzf39/f39/f39/g4ODc3Nzb29ve3t7e3t7e3t7d3d3d3d3d3d11fIV4f4jS09R7goqEipLa2tqprbGusbWIjpXc3Ny0t7qUmaCQlZzW1tfP0NHGyMm6vMCxtLian6TLzM7Bw8Wipat+hY29v8KhparfEdL6AAAAJ3RSTlMA9gjcAxcS+urjzLOfiGY57uXUvriTdG9tX11JSEc/MSwqH+/GxfALfPdwAAADvklEQVRo3rTVi1aqQBQG4BkwQEDwfstLWp1/UCQ10/LS6v2f6pzWYa0y5QcvfQ8wezb/7I3IImcMB526W7F1S0pLtytuvTMYGjlxFVq/XXB0iR+k7hTafU1c6qZhlyQSyJLduBEXMHruPVLcuz3j3Mt7VYkMZNU7pw2tWZbISJab2qnH+484yaN/Uom7WhEnKtbuRFa5Vt7Eycx8K5cx3ILEWWTBEBn4jokzmY4vUt3quIB+K7hcN4+L5Ls0CM2zcCHL08j9vSIuVvSSe+hauAKrm5hvHleRT0ja13Elun90vhxQs9EqWoTjcbiIVqMZKOfmSMAFkx6/iaaBigXTaENLmIXDoFsSzDwM1DdBOAcjWwf7kwb8slMHdi806B+7VauZ7Px1oA4Ea1bBrO3Pm18E8RGoI4IPEEV/r4EHlu9cJaA5PHxvoQliGaoE4RJE89sIlFkDUaASBBF7rOWvYfAkS3ihEi1YztITMaMKYjNWicYbEFUjLtCTIOaKoDHLXlzABRMpIgLjxhH/AbNWxBrM/f+YG6CeFfEMqiH+0ezfK2B/Dlu/9HsFSn0hRFuC2iliB0q2P3804J4U8QSukBOGA26riC04xxBDHTGy68i24/ShGEhwkzUZgwk4ORAdpHkdJ66iV6TpiDrSTJ4TH+kEaerCRarluzrqfYlUrqgg3TY8mvAW6SrCRrrZ6/hYADOks4WOLFbTQO0JpitkoQsLmYyicO/zRCNkYgmJbCajt0XcRbB4G02QjfzbrNntJghDcbyTsDhH4hITkyVmWZza0gO0Ik7QCb7/U213u6jnUGAn4/cAtFy0/X8cZwEK3VRZVjXO5skFppKVqQgkK4GYSVZmYiFZWYhIshKJpWRlKVbSk1SfmjKvqrxsTjqVnqx+HxySz+pskxog/gGgTuy5cq5S5MFZB+07v96/Ta/tfxKs2x59Xd4s7g/srdQtjz4tW47GgiIBa46kbCGE116bWnlQG71HhRchHbWxyhNrNCYdcfF7KWLlTVxcEPGLyfc0B9UJyFNMvovww/2+AdURMOkdA4JZqC9QnQFXZESoCSxUDwrXBKI2FlQPwLWxqBFPVA8Sx4jjUUKmepA5UQIehuiD6sxB42GImGydY5zEqgNx4hzm7YQKpIbzMkcjNX/oSI0KBYfz9E7FmsN5eEWDWX/oYJaKlofzHHKH49zxPndBwV6xcJdE3DUXc1H3/1XjoLI0HEfdy1JYu5X7RnZiM5+Ma2iAYewBGdzYyRZ20dvjeEdP/mJ4hn385xtiGE8/H7qOkgAAAABJRU5ErkJggg=='
};
module.exports = config;